.signupPage{
    background: linear-gradient(to right, rgb(16, 16, 16), rgb(13, 39, 238));
  /* background-image: url("/public/ai-bg.gif"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  margin: 0px;
  padding: 0px;
}

.signupText{
    font-size: .8rem;
    letter-spacing: 2px;
    background-color: #F4F2EC;
    color: black;
    padding: 8px;
    border-radius: 5px;
    text-align: end;
    cursor: pointer;
}

.signupText:hover{
    background-color: #020b26;
    color: white;
    border: 0.5px solid white;
}

.loginText{
    font-size: .8rem;
    letter-spacing: 2px;
    background-color: #F4F2EC;
    color: black;
    padding: 8px;
    border-radius: 5px;
    text-align: end;
    cursor: pointer; 
}

.loginText:hover{
    background-color: #020b26;
    color: white;
    border: 0.5px solid white;
}

/* .signupContainer{
    width: 60%;
    margin-top: 80px;
    height: 450px;
    margin-left: 300px;
    box-shadow: rgba(0, 0, 0, 0.5);
} */

.signupContainer {
    width: 50%;
    margin-top: 20px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 10px;
}

.signupContainer:hover {
    transform: perspective(800px) rotateX(0deg);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}  

.signupContainer::after {
    content: "";
    display: table;
    clear: both;
}

.getAccessText{
    font-size: 1rem;
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
}

.formPage{
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
}

.formRight{
    border: 2px solid black;
    margin-right: 52px;
    margin-top: 55px;
    flex: 1;
    background-image: url("/public/dyr_signup_background.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.mission{
    margin-left: 32px;
    color: white;
    margin-top: 20px;
    font-size: 1rem;
}

.missionText{
    margin-left: 52px;
    margin-top: 80px;
    color: white;
    font-size: 1.3rem;
    font-weight: bold;
    width: 60%;
    line-height: 1.5;
}

.usersWord{
    background-color: #F4F2EC;
    margin-left: 52px;
    margin-top: 80px;
    width: 80%;
    height: auto;
}

.usersText{
    margin-left: 52px;
    padding-top: 20px;
    width: 80%;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
}

.userName{
    margin-top: 20px;
    text-align: center;
    color: white;
    text-align: left;
    margin-left: 52px;
}

.signupButton {
    background-color: #020b26;
    border-color: #020b26;
    border-radius: 50px;
    width: 20%;
    margin: 20px auto; 
    display: block; 
}

.signupDYR{
    background-color: #020b26;
    border-color: #020b26;
    border-radius: 50px;
    width: 30%;
    margin: 20px auto; 
    display: block; 
}

.signinDYR{
    background-color: #020b26;
    border-color: #020b26;
    border-radius: 50px;
    width: 100%;
    margin: 20px auto; 
    display: block;   
}

.exploreButton{
    background-color: #020b26;
    border-color: #020b26;
    border-radius: 50px;
}

.dontReactText{
    text-align: left;
    font-size: 3rem;
    margin-top: 50px;
    margin-left: 100px;
    width: auto;
    line-height: 1.2;
    font-weight: bold;
    color: white;
}

.afterSignUp{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
}

.ourCustomers{
    margin-top: 10px;
    font-size: 1.3rem;
    font-weight: 500;
    background-color: #020b26;
    color: white;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customerText{
   text-align: center;
}

.access {
    margin-left: 100px;
    margin-top: 30px;
}

.accessText {
    font-size: 1rem;
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f4f4ec;
    border: 1px solid #f4f4ec;
    color: black;
    margin-right: 10px;
    margin-top: 10px;
    display: inline-block;
}


.clientContainer{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    margin-left: 25px;
    margin-right: 20px;
    overflow-x: auto;
}

.client1, .client2, .client3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.clientName{
    font: 1rem;
    font-weight: bold;
    margin-top: 20px;
}

.clientTitle{
    font: 0.8rem;
    font-weight: bold;
    margin-top: 20px;
    color: #6c757d;
    text-align: center;
}

.clientReview{
    font: 0.8rem;
    margin-top: 20px;
    color: #6c757d;
    text-align: center;
    line-height: 1.5;
}

.belowDontReact{
    margin-left: 95px;
}

.belowDontReactText{
    font-size: 1rem;
    font-weight: bold;
}

.textAbovePortfolio{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    height: 40px;
    font-weight: bold;
    margin-bottom: 10px;
}

.lineBelowPortfolio{
    width: 30%;
    margin-left: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transform: translateY(-50%);
}

.forget{
    cursor: pointer;
    color: blue;
}

.onlineFormButton{
    width: 30%;
    font-size: 0.875rem;
    margin-left: 20px;
        margin-right: 0px;
        margin-left: 30%;
}

.formLabel{
    width: 50%;
    margin-right: 76px;
    font-weight: bold;
    margin-bottom: 5px;
}

.uploadForm{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.uploadButton{
    width: 50%;
    font-size: 0.875rem;
    margin-left: 20px;
}

.leftSignUp{
    cursor: pointer;
    color: blue;
    margin-left: 75%;
}

.leftSignIn{
    cursor: pointer;
    color: blue;
    margin-left: 63%;
}

.signInWrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.signupLabel:focus {
    border: 1px solid #ccc; 
    outline: none; 
  }

@media (max-width: 768px) {
    .signupText{
        margin-right: 15px;
    }

    .signupContainer{
        height: auto;
        margin-left: 25px;
        width: 90%;
        margin-top: 40px;
    }

    .signupButton{
        width: 50%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .formRight{
        width: 90%;
        margin-left: 25px;
        margin-right: 0px;
    }

    .clientContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 25px;
        margin-right: 20px;
        overflow-x: auto;
    }

    .clientTitle{
        text-align: center;
    }

    .dontReactText{
        font-size: 1.3rem;
        margin-left: 25px;
        margin-top: 20px;
    }

    .belowDontReact{
        font-size: 0.8rem;
        margin-left: 17px; 
    }

    .belowDontReactText{
        font-size: 0.8rem;
    }

    .access{
        margin-left: 25px;
    }

    .accessText{
        font-size: 0.8rem;
        padding-left: 10px;
        padding-right: 10px;
    }

    .signupPage{
        height: 500px;
    }

    .lineBelowPortfolio {
          width: 50%;
          margin-left: 25%;
    }

    .textAbovePortfolio{
        font-size: 1rem;
    }
      
    .client1, .client2, .client3{
        margin: 0px;
        margin-bottom: 20px;
    }

    .signupDYR{
        font-size: 0.8rem;
        width: 61%;
    }

    .signinDYR{
        font-size: 0.8rem;
        width: 100%;
    }

    .loginText{
        margin-right: 15px;
    }

    .leftSignIn{
        margin-left: 0;
        font-size: 0.8rem;
    }

    .leftSignUp{
        margin-left: 0;
        font-size: 0.7rem;
        margin-bottom: 20px;
    }

    .uploadForm{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .uploadButton{
        width: 60%;
        font-size: 0.875rem;
        margin-left: 0px;
    }    

    .formLabel{
        width: 100%;
        margin-right: 0px;
        margin-bottom: 15px;
    }

    .onlineFormButton{
        margin-left: 0px;
        width: 100%;
    }

    .signupLabel{
        font-size: 0.7rem;
    }

    .forget{
        font-size: 0.8rem;
    }
}