.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #020b26;
  color: white;
  text-align: center;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  font-size: 13px;
  z-index: 999;
}

.footer a {
  text-decoration: none;
  color: white;
}

.footerLabel, .incubigHyper{
  font-size: 0.7rem;
}

@media screen and (max-width: 768px) {
  .footer {
    font-size: 12px;
    text-align: center;
    padding-right: 0px;
    height: 30px;
  }
}
