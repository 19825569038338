.table-container {
    height: 600px;
    overflow-y: hidden;
  }
  
  .table-body-container {
    height: 540px;
    overflow-y: auto;
  }
  
  .table-container thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .specific-chart .apexcharts-legend {
    margin-right: 15px;
    width: 300px;
  }

  @media (max-width: 768px) {
    .specific-chart .apexcharts-legend {
      width: 350px;
      display: flex;
      flex-direction: column;
      margin-left: 25px;
      text-align: left;
    }

    .specific-chart .apexcharts-legend.apx-legend-position-bottom{
      flex-wrap: nowrap;
    }
  }
  