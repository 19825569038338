ol li{
    margin-top: 35px;
}

.country-list-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    margin-left: 25px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
  
  .country-name {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 5px;
  }

  .patents-count {
    font-size: 0.7rem;
    color: #555;
  }