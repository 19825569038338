h2.details-title {
  margin-left: 25px;
  margin-top: 25px;
  font-size: 15px;
  font-size: 0.8rem;
  font-weight: 300;
  color: #838c95;
}

.technologyMonitor {
  height: auto;
}

.updateButton{
    padding: 2px 7px;
    font-size: 1rem;
    border-radius: 5px;
    background-color: #020b26;
    color: white;
    margin-left: 20px;
}

@media (max-width: 768px) {
  .technologyMonitor {
    height: auto;
  }

  .updateButton{
    margin-left: 10px;
    margin-top: 10px;
}
}
