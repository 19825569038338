.root {
    display: flex;
    justify-content: center;
    height:auto;
    
    margin: 0;
    padding: 0;
}

.landingBody{
  background: #FAF1EC;
}

.brandName{
    font-weight: bold;
}

/* .searchSection{
    height: 216px;
} */

.searchBox {
    display: flex;
    margin: 50px 10px 50px;
}

.searchBarWrapper {
  position: relative;
  margin-bottom: 10px;
}

.landingSearchIcon{
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  }

.searchDiv{
    display: flex;
    flex-direction: column;
    /* margin-left: 5%; */
}

.landingSearchDiv{
    margin-left: 5%;
}

.searchBar {
  width: calc(100% - 40px);
    height: 42px;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(20, 195, 155, 0.06);
    /* background-color: #020b26;
    color: white; */
    padding-left: 30px;
    padding-right: 33.5%;
    margin-top: 20px;
    border: 0.5px solid black;
    font-size: 1rem;
}

.searchBar::placeholder{
    color: black;
}

.icon {
    position: absolute;
    left: 27.5vw;
    top: 97.2px;
}

.sendIcon { 
    position: absolute;
    right: 28vw;
    top: 102.6px;
    z-index: 100;
}

.button {
    background-color: #020b26; 
    border: none;
    border-radius: 20px;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin-top: 20px;
    cursor: pointer;
  }
  

.section {
    margin-top: 60px;
    margin-bottom: 50px;
    left: 0;
}

.emergingSection {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.emergingText {
    text-align: center;
    color: black;
    padding: 12px 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    font-size: 14px;
}

/* new added styles */
.parallaxClasses{
  height: 300px;
  margin-top: 5%;
}

.searchBox2{
    display: flex;
    flex-direction: column;
}

.searchDiv2{
  display: flex;
    flex-direction: column;
}

.landingSearchDiv2 {
  margin-left: 11px;
}

.brandName-para2 {
  margin-top: 20px;
  color: white;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 3.5rem;
  letter-spacing: 0.01em;
}

.landingBrandname2 {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.1;
  text-align: left;
  width: 50%;
  font-family: Lato, sans-serif !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  letter-spacing: 0.01em;
}

.searchBar2 {
  /* width: calc(100% - 40px); */
  width: 40%;
    height: 42px;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(20, 195, 155, 0.06);
    /* background-color: #020b26;
    color: white; */
    padding-left: 10px;
    padding-right: 3%;
    margin-top: 0;
    border: 0.5px solid black;
    font-size: 1rem;
    margin-left: 15px;
}

.searchBar2::placeholder{
    color: black;
}

.aboveBarPara2{
  color: white;
  margin-top: 10px;
  text-align: left;
  font-size: 1.3rem;
  margin-top: 3%;
  margin-left: 13%;
  /* margin-bottom: 20px; */
}



/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .searchSection {
      height: 180px;
      margin-top: 100px;
    }
  
    .searchBox {
      margin-top: 40px;
    }
  
    .searchBar {
      width: calc(100% - 30px);
    font-size: 0.9rem;
    }
  
    .button {
      font-size: 12px;
    }

    .searchDiv{
      margin-left: 0px;
    }

    /* new added */

    .searchBox2 {
      margin-top: 120px;
    }

    .searchBar2{
      margin-left: 3%;
      margin-top: 25%;
      width: 80%;
      padding-right: 12%;
    }

    .landingSearchDiv2 {
      margin-left: 3%;
      /* margin-bottom: 20px; */
    }

    .aboveBarPara2 {
      font-size: 1rem;
      text-align: center;
      margin-top: 18%;
      margin-left: 0px;
      margin-bottom: 20px;
    }

    .landingPara2{
      font-size: 0.8rem;
      margin-left: 0px;
    }

    .landingBrandname2 {
      line-height: 1;
      font-size: 1rem;
      width: auto;
    }

    .parallaxClasses{
      height: 125px;
      margin-top: 0px;
    }
  }
  
  @media (max-width: 576px) {
    .searchSection {
      height: 220px;
      margin-top: 0px;
    }
  
    .searchBox {
      margin-top: 30px;
    }
  
    .searchBar {
      width: calc(100% - 30px);
    font-size: 0.9rem;
    }
  
    .button {
      font-size: 11px;
    }

    .brandName-para2 {
      font-size: 2rem;
    }
  }