.companyPage{
    margin: 0;
    padding: 0;
}

.companyTag{
    margin-top: 10px;
  font-size: 0.8rem;
  margin-left: 25px;
  width: auto;
  font-weight: bold;
}

.keyPoints{
  font-weight: bold;
  margin-top: 15px;
  margin-left: 25px;
  font-size: 0.8rem;
}

.keyPointsUL{
  list-style: disc;
  margin-left: 40px;
  margin-top: 20px;
}

.keyPointsLI{
  font-size: 0.8rem;
  color: black;
}

@media (max-width: 768px) {
  .keyPoints{
    font-weight: bold;
    margin-top: 10px;
    margin-left: 22px;
    font-size: 0.8rem;
  }

  .keyPointsUL{
    margin-left: 35px;
  }

  .keyPointsLI{
    font-size: 0.8rem;
  }
}

  
