
.scrollableContainer {
    display: flex;
    overflow-x: auto;
  }
  
  .columnsContainer {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    /* text-align: center; */
  }
  
  .patent-card {
    flex: 0 0 auto;
    width: 300px; 
    margin: 0 10px; 
    height: 180px;
    background-color: transparent;
    color: white;
  }

  .signUpClients{
    font-size: 1.3rem;
    width: 200px;
    margin: 6px;
    white-space: normal;
    word-wrap: break-word;
  }

  @media (max-width: 768px) {
    .signUpClients{
      font-size: 1rem;
      width: 200px;
      margin: 6px;
      white-space: normal;
      word-wrap: break-word;
    }
  }
  