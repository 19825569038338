.tmln {
  background-color: hsl(200, 40%, 80%);
  border-radius: 0.25rem;
  color: hsl(200, 40%, 1%);
  font-family: ui-sans-serif, system-ui, sans-serif;
  padding-block: 1rem 0;
  padding-bottom: 1rem; /* Safari */
  padding-top: 1rem; /* Safari */
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.tmln:not(.tmln--hr) {
  padding-inline-start: 1rem;
}

.tmln__header {
  margin-block-start: 0;
}

.tmln__item {
  color: inherit;
  display: block;
  margin-block-end: 1rem;
  padding-inline-start: calc(1rem + 1.2em);
  position: relative;
  text-decoration: none;
}

/* Circle */
.tmln__item::after {
  border: 2px solid hsl(200, 40%, 60%);
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  block-size: 1.2em;
  inline-size: 1.2em;
  inset-block-start: 0;
  inset-inline-start: 0;
  position: absolute;
  transition: all 0.3s ease-out;
}

/* Line */
.tmln__item::before {
  background-color: hsl(200, 40%, 60%);
  content: "";
  block-size: calc(100% + 1rem - 1.2em);
  inline-size: 2px;
  inset-block-start: 1.2em;
  inset-inline-start: calc((1.2em - 2px) / 2);
  position: absolute;
}

/* Hide last line */
.tmln:not(.tmln--hr) .tmln__item:last-child::before {
  display: none;
}

.tmln__item-headline {
  margin-block: 0;
}

.tmln__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-block: 0 1rem;
  padding-inline: 0;
}

/* Modifiers and States */
/* Active Circle */
.tmln__item--active::after {
  border-color: hsl(200, 40%, 40%);
  box-shadow: 0 0 0 calc(1.2em - 2px) hsl(200, 40%, 80%), 0 0 0 1.2em hsl(200, 40%, 40%);
  background-color: hsl(200, 40%, 40%);
}

/* :focus, :focus-within, :focus-visible */
.tmln__item--active:focus-visible::after,
.tmln__item--active:focus-within::after {
  box-shadow: 0 0 0 calc(1.2em - 2px) hsl(200, 30%, 90%), 0 0 0 1.2em hsl(200, 40%, 60%);
}

.tmln__item--active [data-title],
.tmln__item:focus-visible [data-title] {
  text-shadow: 0.75px 0px 0px currentColor;
}

.tmln__item:not(.tmln__item--active):focus-visible::after,
.tmln__item:not(.tmln__item--active):focus-within::after {
  background-color: hsl(200, 30%, 90%);
  box-shadow: 0 0 0 calc(1.2em - 2px) hsl(200, 40%, 80%), 0 0 0 1.2em hsl(200, 40%, 60%);
}

.tmln--box .tmln__item:focus-within {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.tmln__item:focus-visible {
  outline: none; 
}

.tmln__item:focus-visible [data-title],
.tmln__item:focus-within a {
  outline: 1px dotted currentColor;
  outline-offset: 6px;
}

/* Horizontal */
.tmln--hr .tmln__header {
  margin-inline-start: 1rem;
}

.tmln--hr .tmln__list {
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-block-start: 6px; /* Add room for box-shadow transition in horizontal mode */
  padding-inline-start: 1rem;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
}

.tmln--hr .tmln__item {
  align-self: flex-start;
  margin-block-end: 0;
  min-width: 6rem;
  padding-block-start: calc(1.2em + 1rem);
  padding-inline-end: 1rem;
  padding-inline-start: 0;
  scroll-snap-align: start;
  scroll-margin-inline-start: 1rem;
  scroll-margin-left: 1rem; /* Safari */
}

.tmln--hr .tmln__item::before {
  block-size: 2px;
  inline-size: calc(100% - 1.2em);
  inset-block-start: calc((1.2em - 2px) / 2);
  inset-inline-start: calc(0% + 1.2em);
}

/* Item Box */
.tmln--box .tmln__item {
  background-color: rgba(255, 255, 255, .15);
  border-radius: 0.25rem;
  margin-block-end: 0;
  margin-inline-start: calc(1.2em + 1rem);
  padding: 1rem;
  transition: box-shadow 0.3s ease-out;
}

.tmln--box .tmln__item [data-title] {
  display: block;
  font-size: small;
  text-transform: uppercase;
}

.tmln--box:not(.tmln--hr) .tmln__item::after {
  inset-inline-start: calc(0px - 1.2em - 1rem);
}

.tmln--box:not(.tmln--hr) .tmln__item::before {
  inset-inline-start: calc(0px - 1rem - ((1.2em + 2px) / 2));
}

.tmln--box .tmln__item--bg-dark {
  background-color: hsl(200, 40%, 40%);
  color: hsl(200, 40%, 95%);
}

.tmln--box:not(.tmln--hr) .tmln__item--bg-dark a {
  outline-color: hsl(200, 40%, 40%);
}

.tmln--box .tmln__item--bg-lght {
  background-color: hsl(200, 30%, 90%);
}

.tmln--box .tmln__list {
  gap: 1rem;
  padding-inline-end: 1rem;
}

/* Item Box AND Horizontal */
.tmln--box.tmln--hr .tmln__item {
  min-width: 85%;
  margin-block-end: 0;
  margin-block-start: calc(1.2em + 1rem);
  margin-inline-start: 0;
}

.tmln--box.tmln--hr .tmln__item::after {
  inset-block-start: calc(0px - 1.2em - 1rem);
}

.tmln--box.tmln--hr .tmln__item::before {
  inset-block-start: calc(0px - 1rem - ((1.2em + 2px) / 2));
  inline-size: calc(100% - 1.2em + 1rem);
}

/* Single Line, center text to bullet */
.tmln--sl .tmln__item {
  margin-bottom: 1rem;
  line-height: 1.2em; /* Center text to bullet */
}

/* Media Queries */
/* :hover */
@media (hover: hover) {
  .tmln__item--active:hover::after {
      box-shadow: 0 0 0 calc(1.2em - 2px) hsl(200, 30%, 90%), 0 0 0 1.2em hsl(200, 40%, 60%);
  }
  .tmln__item:hover [data-title] {
      text-shadow: 0.75px 0px 0px currentColor;
  }
  .tmln__item:not(.tmln__item--active):hover::after {
      background-color: hsl(200, 30%, 90%);
      box-shadow: 0 0 0 calc(1.2em - 2px) hsl(200, 40%, 80%), 0 0 0 1.2em hsl(200, 40%, 60%);
  }
  .tmln--box .tmln__item:hover {
      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
}

@media (min-width: 768px) {
  .tmln--box.tmln--hr .tmln__item {
      min-width: 30%;
  }
}

/* Show scrollbars on devices without touch */
@media (pointer: fine) {
  .tmln--hr {
      padding-block-end: 1rem;
  }
  .tmln--hr .tmln__list {
      scrollbar-width: 10px;
  }
  .tmln--hr .tmln__list {
      scrollbar-color: hsl(200, 40%, 70%) hsl(200, 40%, 85%);
      scrollbar-width: thin;
  }
  .tmln--hr .tmln__list::-webkit-scrollbar {
      background: hsl(200, 40%, 85%);
      height: 10px;
      width: 10px;
  }
  .tmln--hr .tmln__list::-webkit-scrollbar-button {
      background: hsl(200, 40%, 80%);
  }
  .tmln--hr .tmln__list::-webkit-scrollbar-thumb {
      background-color: hsl(200, 40%, 70%);
  }
}

/* For demo */

body * {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/* Helpers */
.tmln__item a {
  color: currentColor;
}

.block-link {
  inset: 0;
  overflow: hidden;
  position: absolute;
  text-indent: 200%;
  white-space: nowrap;
}
