/* .grid-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    margin-top: 20px;
}

.upper-grid{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.lower-grid{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.lastGrid{
    width: 80%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; 
}

.grid-item{
    width: 100%;
    border: 0.5px solid rgb(199, 198, 198);
    padding: 20px;
    height: auto;
}

.grid-item h2{
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}

.grid-item p{
    font-size: 0.6rem;
    margin: 6px;
    font-weight: 400;
    text-align: center;
}

@media (max-width: 768px) {
    .grid-item {
        height: auto;
    }
} */


/* Container for the grid */
.grid-container {
    margin: 0px auto;
    padding: 10px;
    max-width: 1200px; 
  }
  
  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    /* margin-bottom: 20px; */
    font-size: 1rem; 
    font-weight: bold;
  }
  
  .grid-item {
    background:white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 10px;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: calc(50% - 140px); 
  }

  .grid-item2{
    background:white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 10px;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: calc(50% - 140px); 
  }
  
  .upper-grid,
  .lower-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .grid-item h2, .grid-item2 h2 {
    font-size: 1rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .grid-item p, .grid-item2 p {
    font-size: 0.8rem;
  }

  .lastGrid {
    margin-top: 20px;
  }
  
  .lastGrid .grid-item {
    padding: 20px;
    text-align: center;
    cursor: pointer; 
  }
  
  .lastGrid .grid-item h2 {
    text-decoration: underline;
    color: #f9979a 
  }

  .lastGrid .grid-item2 h2{
    text-decoration: underline;
    color: #f9979a 
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .heading {
      font-size: 1.2rem; 
      padding: 10px;
    }

    .grid-item2{
      padding: 15px;
      margin: 8px;
      width: 90%
    }
  
    .grid-item {
      padding: 15px 6px;
      margin: 8px;
      /* width: calc(51% - 85px);  */
      width: 27%;
    }
  
    .grid-item h2, .grid-item2 h2 {
      font-size: 1rem; 
    }
  
    .grid-item p, .grid-item2 p {
      font-size: 0.7rem; 
    }

    .heading{
      font-size: 0.7rem;
    }
  }
    
  