.barSearch{
    font-size: 1rem;
}

.heading{
    font-size: 1rem;
}

.headPage{
    font-size: 1rem;
}

.new-details h2.details-title{
    font-size: 0.8rem;
}

.new-details h1{
    font-size: 1.3rem;
}

.new-details.patent .abstract-container .abstract{
    font-size: 1rem;
}

.abstract h1{
    font-size: 1rem;
}

.basic-info h2{
    font-size: 0.8rem;
}

.date{
    font-size: 0.8rem;
}

.col-item h3{
    font-size: 1rem;
}

.col-item p{
    font-size: 0.8rem;
}

