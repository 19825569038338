.noLoginText{
    text-align: center;
    font-weight: bold;
    margin-top: 10%;
}

.notLoggedSignin{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 46%;
    margin-top: 1%;
}

@media (max-width: 768px) {
    .notLoggedSignin{
        margin-left: 36%;
    }
}