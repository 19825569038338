.technologyPage{
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  overflow-x: hidden;
}

.technologyMonitor p {
  font-size: 1.3rem;
  font-weight: bolder;
  margin-bottom: 10px;
}

.technologyMonitor div {
  margin-left: 11px;
  font-size: 0.75rem;
}

.key-metrics {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}

.metric {
  margin-right: 50px;
  font-size: 1rem;
  font-weight: normal;
}

.techIndustry {
  font-size: 1rem;
  font-weight: 400;
  color: #838c95;
}

.numberRings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.thin-horizontal-line {
  border: none;
  height: 1px;
  background-color: #000000;
  margin: 8px 0;
}

.forTechnologyTag {
  margin-top: 10px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 25px;
  background-color: #020b26;
  color: white;
  width: 20%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.technologyTag {
  margin-left: 7px;
}

.technologyOverviewTag {
  font-weight: 500;
  margin-left: 25px;
  background-color: #020b26;
  color: white;
  width: 32%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.overviewTag {
  margin-left: 7px;
  font-size: 0.8rem;
}

.button-grid {
  margin-top: 10px;
  margin-left: 25px;
}

.option-button {
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
  margin-top: 10px;
  padding: 10px 16px;
  margin-right: 10px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1.92px solid;
  border-color: #eff0f6;
  border-radius: 8px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.option-button:hover {
  background-color: #020b26;
  color: white;
  border-radius: 8px;
}

.refContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-top: -15px;
}

.refButton {
  font-size: 0.6rem;
  padding: 10px 13px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  background-color: #f4f2ec;
  color: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  width: 150px;
}

.refButton:hover {
  background-color: #020b26;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

/* .summaryTag{
    margin-top: 30px;
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 17px;
    background-color: #020b26;
    color: white;
    width: 25%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
} */

.summaryTagText {
  margin-left: 7px;
}

.box-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}

.box-container2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.box {
  background-color: white;
  color: black;
  padding: 10px;
  text-align: center;
  width: 100%;
  height: auto;
  max-width: 200px;
}

/* .boxNew{
  background: transparent;
  color: white;
  padding: 10px;
  text-align: center;
  width: 100%;
  height: auto;
  max-width: 200px;
} */

.boxNew {
  background: transparent;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  position: relative; 
}

.icon2 {
  font-size: 1.5rem; 
  color: white; 
  margin-bottom: 10px; 
  transition: transform 0.3s; 
}

.icon2:hover {
  transform: scale(1.2); 
}

.value {
  font-size: 2rem;
  font-weight: bold;
  /* color: white; */
  position: relative;
  /* display: inline-block; */
  text-align: center;
}

.value::before,
.value::after {
  content: attr(data-value);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  color: rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.value::after {
  transform: translateY(2px);
}

.landingBody3 {
  font-size: 0.7rem;
  color: white;
  margin-top: 10px;
  text-shadow: 0.5px 0.5px 0 rgba(0, 0, 0, 0.3);
}

.box-container2 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.box2 {
  background-color: white;
  color: black;
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(33.33% - 50px);
  height: 109px;
}

.value2 {
  font-size: 1rem;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
}

.beforeSummary {
  height: 100px;
  background-color: #020b26;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.beforeSummaryText {
  text-align: center;
  width: 70%;
  font-size: 1.3rem;
  line-height: 1.2;
}

.suggestionTag {
  margin-top: 30px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 25px;
  background-color: #020b26;
  color: white;
  width: 10%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.suggestionsText {
  margin-left: 25px;
  margin-top: 25px;
  font-size: 0.8rem;
}

.listDownload {
  text-align: right;
  margin-top: 90px;
  text-decoration: underline;
}

ul li {
  color: #6b859e;
  font-size: 0.7rem;
}

.landscapeTag {
  margin-top: 30px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 25px;
  width: 25%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bolder;
}

.globalTechText{
  margin-top: 30px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 25px;
  width: 38%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bolder;
}

.countryContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 40px;
}

.countryBox{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  background-color: #f7f8fb;
  padding: 20px;
  font-size: 0.7rem;
  border-radius: 10px;
}

.chartWithText {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.pieText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.pieText p{
  font-size: 0.8rem;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .box{
    width: auto;
    height: auto;
  }

  .value{
    font-size: 1.5rem;
  }

  .boxNew {
    padding: 0px; 
  }
}