.brandLine {
  margin-top: 12px;
}

.brandName {
  font-weight: bold;
  font-size: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.logo {
  width: 35px;
  height: 35px;
  position: absolute;
  left: 20px;
}

.logoText {
  margin-left: 0px;
  width: 100px;
  font-size: 0.8rem;
  font-weight: bold;
  top: 20px;
  color: #020b26;
  position: absolute;
  letter-spacing: 2px;
  text-decoration: none;
}

.btn-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 12px;
}

.personalizeLanding {
  background-color: #f4f2ec;
  border: 0px;
  color: #020b26;
  font-size: 0.8rem;
  letter-spacing: 1px;
  top: 20px;
  position: absolute;
  z-index: 1;
}

.personalizeLanding:hover {
  background-color: #020b26;
  color: white;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .logoText {
    font-size: 0.75rem;
  }
}

@media (max-width: 576px) {
  .brandName {
    font-size: 18px;
  }

  .logo {
    width: 30px;
    height: 30px;
  }

  .logoText {
    font-size: 0.7rem;
    margin-top: -3px;
  }
}

@media screen and (max-width: 768px) {
  .personalizeLanding{
    top: 5px;
  position: relative;
  }
}
