$peter-river:grey;
$clouds:#ecf0f1;

.bookBody{
  width:100%;
  height: 50vh;
//   background:$peter-river;
}
.bookText{
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}
.book{
  top: 30%;
  transform: translateY(-50%);
  position:relative;
  margin:0 auto;
  border:5px solid $clouds;
  width:100px;
  height:60px;
}
.book__page{
  position:absolute;
  left:50%;
  top:-5px;
  margin:0 auto;
  border-top:5px solid $clouds;
  border-bottom:5px solid $clouds;
  border-right:5px solid $clouds;
  background:$peter-river;
  width:50px;
  height:60px;
  transform-origin:0% 50%;
  animation:flip 1.2s infinite linear;
  animation-fill-mode:forwards;
  
  @for $i from 1 through 3 {
    &:nth-child(#{$i}) { 
      z-index:-$i;
      animation-delay:1.4s*$i;
    }
  }
}

@keyframes flip {
  0%{
      transform: perspective( 600px )
      rotateY( -0deg );
  }
  
  20%{
    background:darken($peter-river,10%);
  }
  
  29.9%{
      background:darken($peter-river,10%);
  }
  30%{
      transform: perspective( 200px )
      rotateY( -90deg );
      background:$peter-river;
  }
  
  54.999%{
    opacity:1;
  }
  55%{
    opacity:0;
  }
  
  60%{
    transform: perspective( 200px )
    rotateY( -180deg );
    background:$peter-river;
  }
  
  100%{
    transform: perspective( 200px )
    rotateY( -180deg );
    background:$peter-river;
  }
}