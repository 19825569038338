.patentPage{
    margin: 0;
    padding: 0;
}

.forSearch{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    top: 60px;
}

.searchContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.barSearch{
    width: 40vw;
    height: 42px;
    border-radius: 10px;
    padding-left: 30px;
    margin-top: 20px;
    border: 0.5px solid rgb(222, 219, 219);
    font-size: 1rem;
    margin-left: 20px;
}

.barSearch:hover{
    box-shadow: 0 15px 20px rgba(14, 14, 14, 0.06);
}

.afterSearch{
    position: relative;
    top: 50px;
    height: 0.1px;
    color: #f9f9f9;
}

.aftterInfoTable{
    top: 200px;
    position: relative;
}

.headingPatent{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    margin-top: 10px;
    background-color: #020b26;
    color: white;
    height: 40px;
}

.headPage{
    top: 85px;
    position: relative;
    text-align: center;
    font-size: 1rem;
}

.content-wrapper{
    width: 100%;
    margin-left: 25px;
    top: 50px;
}

.new-details{
    margin: 20px auto;
}

.new-details h2.details-title {
    margin-top: 44px;
    font-size: 15px;
    font-size: 1rem;
    font-weight: 300;
    color: #838c95;
}

.raw{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.new-details.patent .raw.patent-info {
    margin-top: 20px;
}

.new-details.patent section.abstract {
    float: left;
    width: 470px;
}

.new-details h1 {
    margin-top: 4px;
    color: #3e4b5c;
font-size: 25px;
font-size: 2.5rem;
line-height: 30px;
line-height: 1.2;
min-height: 19px;
font-weight: 400;
}

.new-details.patent .abstract-container .abstract {
    font-size: 1.2rem;
    line-height: 1.41667;
    margin-top: 14px;
}

.abstract{
    width: 90%;
}

.abstract h1{
    margin-bottom: 20px;
    line-height: 1.5;
    color: black;
    font-size: 1rem;
    line-height: 1.2;
    min-height: 19px;
    font-weight: bold;
}

/* .basic-info{
    margin-top: 20px;
    min-height: 265px;
    width: 75%;
    margin-left: 52px;
}

.basic-info h3{
    position: relative;
    font-size: 0.875px;
    color: white;
    background: #020b26;
    padding: 6px 7px 6px;
    border: 1px solid #dfe9f3;
    border-bottom-color: #d3dde9;
    width: 30%;
} */

.downloadButton{
   margin-top: 60px;
   margin-left: 5px;
   text-decoration: underline;
   color: black;
   cursor: pointer;
   width: 100px;
   height: 25px;
}

.infoText{
    top: 40px;
    font-size: 1rem;
    position: relative;
    margin-left: 40px;
}

.date{
    top: 50px;
    font-size: 1rem;
    position: relative;
    margin-left: 40px;
}

.processing-time {
    height: 62px;
    padding-left: 20px;
    padding-right: 19px;
}

.processing-time h3 {
    margin-top: 18px;
    font-size: 1rem;
    font-weight: 700;
    color: #020b26;
}

.chart-container {
    position: relative;
    top: 12px;
    height: 11px;
}

.bar {
    height: 10px;
    position: absolute;
    top: 0;
}

.marker {
    position: absolute;
    top: -4px;
    width: 1px;
    height: 20px;
    background: #4f5f70;
}

.processing-time-annotations {
    position: relative;
    top: 21px;
}

.processing-time-annotations p:first-child {
    float: left;
}

.processing-time-annotations strong {
    font-weight: 600;
}

.processing-time-annotations p:last-child {
    float: right;
}

.dates {
    margin-top: 35px;
    padding-right: 19px;
}

.col-item:nth-child(odd) {
    width: 56%;
}

.col-item {
    float: left;
}

.col-item h3 {
    font-size: 15px;
    font-weight: 700;
    color: #020b26;
}

.col-item p {
    font-size: 15px;
    margin-top: 6px;
}

.col-item:nth-child(2n) {
    width: 44%;
}

.col-item {
    float: left;
}

.identity {
    margin-top: 65px;
    padding-right: 19px;
}

.title{
    font-weight: normal;
}

.raw.lists:not(.second-row) {
    margin-top: 70px;
}

section.lists {
    display: flex;
    justify-content: space-between;
}

.detail-list {
    box-sizing: border-box;
}


.raw.lists .detail-list {
    width: 299px;
}

.lists .detail-list {
    margin-top: -4px;
    margin-bottom: 0;
}

.detail-list h3 {
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 700;
    color: #020b26;
}

.content-wrap {
    max-height: 174px;
    margin-top: 3px;
    min-height: 244px;
}

.scrollarea, .scrollarea-content {
    overflow: hidden;
    position: relative;
}

.content {
    padding-top: 4px;
}

.scrollarea-content {
    overflow: hidden;
    position: relative;
    padding: 0 10px 0 0;
    margin-top: 10px;
    margin-left: -5px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    box-sizing: border-box;
    text-indent: initial;
}

table td{
    padding-bottom: 5px;
}

tbody {
    display: table-row-group;
}

td:first-child {
    padding-left: 10px;
    padding-top: 10px;
    border: 1ps solid white;
}

td {
    vertical-align: top;
    display: table-cell;
    padding-top: 8px;
}

.detail-list.classes ul {
    margin-left: 15px;
}

ul{
    list-style: none;
}

ol{
    list-style-type: decimal;
}

.new-details ul {
    margin: 0;
    padding: 0;
}

.detail-list.classes li {
    line-height: 14px;
}

a, a:hover, a:hover:visited, a:visited {
    cursor: pointer;
    color: inherit;
}

.raw.lists .detail-list:not(:first-child) {
    margin-left: 80px;
}

.detail-list:not(.classes) ol {
    counter-reset: li;
    margin-bottom: 3px;
}

.detail-list ol {
    margin-left: 26px;
    margin-top: 15px;
}

.detail-list ol li{
    margin-bottom: 20px;
}

.detail-list:not(.classes) ol li:first-child {
    margin-top: 11px;
}

.detail-list:not(.classes) ol .primary {
    font-size: 1rem;
    line-height: 17px;
}

 .detail-list li a {
    text-decoration: auto;
}

.inventors ol li{
    list-style: decimal;
}

.assignees{
    top: 250px;
    position: relative;
    width: 50%;
    margin-left: 50px;
}

.inventors{
    margin-left: 50px;
}

.classes{
    margin-left: 50px;
}

.assignees ol li{
    list-style: decimal;
}

.detail-list:not(.classes) ol a, .detail-list:not(.classes) ol p {
    display: contents;
    position: relative;
}

.similarPatents{
    align-items: start;
    text-align: left;
    margin-left: 32px;
    font-size: 1.5rem;
}


.patentDetails {
    border-collapse: collapse;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 30px;
  }
  
  .patentDetails th {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .patentDetails td {
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  .patentDetails .detail {
    text-decoration: none;
    color: black;
    font-size: smaller;
  }

  .patentBanner{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 25px;
    background-color: #020b26;
    color: white;
    font-size: 1.3rem;
  }

  .patentBannerText{
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
  }

  .afterClaim{
    margin-top: 50px;
  }

  .technologyBanner{
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #020b26;
    color: white;
    font-size: 1.3rem;
    height: 120px;
    margin-top: 50px;
  }

  .technologyText{
    font-size: 1.3rem;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
  }

  .researchArea{
    display: flex;
    flex-direction: column;
  }

  .researchAreaText{
    margin-left: 25px;
    margin-top: 10px;
    font-size: 1rem;
    width: 80%;
    line-height: 1.3;
  }

  .researchAreaChart{
    margin-left: 25px;
    margin-top: 20px;
    width: 90%;
  }

  .patentResearch{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 90px;
    margin-left: 40px;
    height: 100%;
  }

  .summaryTagPatent{
    margin-top: 50px;
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 17px;
    background-color: #020b26;
    color: white;
    width: 35%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .patentNum{
    height: 30%;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    color: #020b26;
  }

  .patentText{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 30%;
    font-size: 1rem;
  }

.citation{
    display: flex;
    flex-direction: row;
    margin-left: 60px;
    margin-top: 100px;
    width: 100%;
}

.citationInfo{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 50px;
}

.citationNum{
    font-size: 1rem;
    font-weight: 600;
}

.citationText{
    font-size: 0.8rem;
    margin-top: 10px;
}

  .patentViewTag{
    margin-top: 20px;
    font-weight: 500;
    margin-left: 52px;
    background-color: #020b26;
    color: white;
    width: 20%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .patentViewText{
    font-size: 0.8rem;
    margin-left: 7px;
  }

  .patentRelatedTag{
    margin-top: 50px;
    font-weight: 500;
    margin-left: 52px;
    background-color: #020b26;
    color: white;
    width: 30%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .patentRelatedText{
    font-size: 0.8rem;
    margin-left: 7px;
  }

  .patentNavTag{
    margin-top: 70px;
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 52px;
    background-color: white;
    color: #020b26;
    width: 35%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .patentNavText{
    margin-left: 7px;
  }

  .beforeSimilar{
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #020b26;
    color: white;
    font-size: 1.3rem;
    height: 120px;
    margin-top: 100px;
  }

  .beforeSimilarText{
    font-size: 1.3rem;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2;
  }

  .patentSimilarTag{
    margin-top: 40px;
    font-weight: 500;
    margin-left: 52px;
    background-color: #020b26;
    color: white;
    width: 18%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .patentSimilarText{
    font-size: 0.8rem;
    margin-left: 7px;
  }

  #claimsText p{
    font-size: 0.8rem;
  }

  .downloadButton{
    margin-top: 30px; 
    margin-left: 2px; 
    cursor: pointer; 
    margin-bottom: 30px;
    width: 12%;
  }

  @media (max-width: 768px) {
    .downloadButton{
        margin-bottom: 50px;
    }
  }