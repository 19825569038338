.scrollableContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}

.PatentList {
  flex: 0 0 50%;
  max-width: calc(50% - 20px);
  margin-right: 20px;
}

.reportCard {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.belowForYou {
  width: 30%;
  margin-left: 500px;
  display: "flex";
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(-50%);
}

.foryouReact {
  margin-left: 15px;
  margin-top: 1%;
}

.foryouPara {
  margin-left: 1.5%;
}

.foryouLine {
  margin-left: 1.5%;
}

.orText::before {
  background-color: #cbc4c4;
  content: "";
  display: inline-block;
  height: 0.5px;
  position: relative;
  vertical-align: middle;
  width: 25%;
  margin-right: 10px;
}

.orText::after {
  background-color: #cbc4c4;
  content: "";
  display: inline-block;
  height: 0.5px;
  position: relative;
  vertical-align: middle;
  width: 25%;
  margin-left: 10px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  background-color: #F4F2EC;
  color: black;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.8rem;
}

.tag:hover {
  background-color: #020b26;
  color: white;
}

.noLoginText{
  text-align: center;
  font-weight: bold;
  margin-top: 10%;
}

.errorMessage{
  color: red;
}

.userOverview{
  margin: 25px 20px;
}

.overviewBlock{
  margin: 10px;
    padding: 20px; 
    border: 0.5px solid #ddd; 
    border-radius: 8px; 
    background-color: white; 
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
    transition: box-shadow 0.3s ease;
}

.blockOne{
  /* background-color: #020b26;
  color: white; */
  display: flex;
  flex-direction: row;
  margin-right: 200px;
}

.credits{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.userImg{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userDesc{
  text-align: center;
  margin-bottom: 0.5rem;
}

.uname{
  font-size: 1.5rem;
  font-weight: 700;
}

.udesc{
  color: #6c757d;
}

.uButton{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
}

.uInfo{
  font-size: 1rem;
  font-weight: 700;
  margin-top: 2.75rem;
}

.editButton{
  border-radius: 5px;
  padding: 5px;
  background-color: #F4F2EC;
  border: 2px solid #F4F2EC;
}

.editButton:hover{
  background-color: #020b26;
  color: white;
}

.cardsContainerInsight {
  display: flex;
  gap: 20px; /* Space between the cards */
  justify-content: space-between;
}

.cardInsight {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: calc(25% - 20px); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.cardInsight p {
  margin-bottom: 20px;
  font-weight: bold;
  min-height: 60px; 
  display: flex;
  align-items: center;
}

.cardInsight h4 {
  margin-top: auto; 
}


.card1{
  background-color: #feeaec;
}
.card2{
  background-color: #f5eddf;
}
.card3{
background-color: #d1f1d1;
}
.card4{
background-color: #e0cff1;
}

.filterContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.dropdownInsight {
  padding: 5px;
  font-size: 0.8rem;
  max-width: 200px; 
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
}

.list-group-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* .activityMap{
  margin-left: 10rem;
} */

.dropdownContainer{
  position: relative;
  top: 0;
  right: 0; 
  left: 12%;
}

.idfLinks{
  margin-left: 40%;
}

.labelTechnology{
  margin-right: 10px;
  margin-left: 15px;
}

.newPatentTag{
  margin-top: 0px;
}

@media (max-width: 768px) {
  .scrollableContainer {
    overflow-x: auto;
    max-height: 400px;
  }
  .columnsContainer {
    display: flex;
    flex-wrap: nowrap;
  }
  .PatentList {
    flex: 0 0 200px;
    max-width: 100%;
    margin-right: 10px;
  }
  .belowForYou {
    margin-left: 25%;
    width: 50%;
  }

  .foryouReact {
    margin-left: 10px;
  }

  .foryouPara {
    font-size: 1rem;
    margin-left: 15px;
  }

  .foryouLine {
    margin-left: 6%;
  }

  .reportCard {
    position: fixed;
    width: 80%;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .stateArtTag{
    display: flex;
    flex-direction: column;
  }

  .stateArtRow{
    margin-top: 50px;
  }

  .dropdownContainer{
    top: 10px;
    left: 1%;
  }

  .inline-display{
    display: flex;
    flex-direction: column;
  }

  #researchAreaDropdown{
    width: 100%;
  }

  .researchAreaDropdown{
    margin-top: 20px;
  }

  .customTh, .tableColumnInsight {
    font-size: 0.8rem;
    padding: 8px;
  }

  .tableHeading {
    font-size: 1rem;
    text-align: center;
  }

  .desktopTh {
    width: auto !important;
    font-weight: normal !important;
    background-color: transparent !important;
  }

  .labelTechnology{
    margin-bottom: 10px;
  }

  .selectTechnology{
    margin-left: 10px;
  }

  .newPatentTag{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}


/* SearchHistoryList.css */
.search-history-list {
  margin-left: 20px;
  margin-right: 20px;
  width: 90%;
}

.list-group-item {
  border: 1px solid #dbdbde;
  /* background: linear-gradient(135deg, #f5f7fa, #c3cfe2); */
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.list-group-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.item-title {
  font-weight: bold;
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.patentdetails-titleInsight {
  font-size: 0.7rem;
  color: #838c95;
  margin-top: 20px;
}

.item-abstract {
  font-size: 0.8rem;
  margin-top: 20px;
}

.scrollable-containerInsight {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  border-radius: 10px;
}

.card-wrapperInsight {
  flex: 0 0 auto;
  margin-right: 20px;
  white-space: normal;
}

.hovered {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-wrapperInsight .details-patent {
  font-size: 1rem;
  margin: 10px 0;
  white-space: normal;  
  overflow: hidden;     
  text-overflow: ellipsis;  
  word-wrap: break-word;
}

.card-wrapperInsight .patentDescription {
  font-size: 0.9rem;
  color: #666;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

/* .card-wrapperInsight .card-title {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.card-wrapperInsight .badge {
  position: absolute;
  top: 10px;
  right: 10px;
}

.card-wrapperInsight .card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.customTableInsight {
  width: 100%;
  overflow-x: auto;
}

.customTh {
  padding: 10px;
}

.tableColumnInsight {
  padding: 10px;
}

.rowTableInsight {
  text-align: center;
}

.inline-display {
  /* background-color: #46bac4; */
  padding: 15px;
  border: 1px solid white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.inline-display .technology-name {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.inline-display select {
  font-size: 1rem;
  padding: 5px;
}

.indirect-competitors-grid {
  padding: 20px;
}

.indirect-competitors-grid .card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.indirect-competitors-grid .card:hover {
  transform: scale(1.05);
}

.indirect-competitors-grid .card-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.indirect-competitors-grid .card-text {
  margin-bottom: 1rem;
}

.indirect-competitors-grid .btn {
  display: block;
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}

.university-activities-table {
  margin: 20px 0;
  border: 1px solid #ddd;
}

.university-activities-table thead {
  background-color: #f8f9fa;
}

.university-activities-table th,
.university-activities-table td {
  padding: 12px;
  text-align: left;
}

.striped-table {
  margin-top: 20px;
    margin-left: 25px;
    width: 95%;
    border: 0.5px solid #dbdbde;
    border-radius: 20px;
}

.striped-table th,
.striped-table td {
  padding: 17px;
}

.striped-table th{
  border-bottom: 3px solid #dbdbde;
}

.striped-table th {
  font-weight: bold;
}

.striped-table tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.striped-table tbody tr:hover {
  background-color: #f5f5f5;
}

.customSkeleton {
  background-color: #e0e0e0;  
  border-radius: 4px;          
  margin-bottom: 10px;         
  height: 20px;               
}

.customSkeletonContainer {
  padding: 10px;
}