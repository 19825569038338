.landingPage {
  background-image: url("/public/output-onlinegiftools.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  margin: 0px;
  padding: 0px;
}

.justText {
  font-size: 1.3rem;
  margin-left: 100px;
  margin-top: 20px;
}

.landingFirstBanner {
  margin-top: 250px;
  height: 180px;
  background-color: #020b26;
  color: white;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.researchText {
  font-size: 2rem;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  height: 100%;
}

.elaboratedText {
  margin-top: 0px;
  font-size: 1.3rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.researchTextLast {
  font-size: 2rem;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  height: auto;
  margin-left: 300px;
  margin-top: 50px;
}

.elaboratedTextLast {
  margin-top: 75px;
  font-size: 1.3rem;
  line-height: 1.2;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  height: 100%;
  margin-left: 100px;
}

.landingSignupHyper {
  font-size: 1.3rem;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionBig {
  font-size: 1.3rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
}

.sectionSmall {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 0px;
}

.head {
  text-align: center;
  font-size: 0.8rem;
  color: black;
}

.emergingSectionContainer {
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: 0;
  gap: 16px;
  height: 150px;
  white-space: nowrap;
}

.emergingSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.emergingText {
  text-align: center;
  background-color: white;
  color: black;
  padding: 1px 35px;
  border: 1.5px solid black;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  min-width: 170px;
  flex: 0 0 auto;
  height: 100px;
  text-align: center;
  font-weight: 600;
}

.emergingTextMain {
  position: absolute;
  top: 30%;
  margin-left: 50px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.emergingTextAdditional {
  font-size: 0.8rem;
  margin-top: 60px;
  font-weight: 100;
}

.halfScreen {
  width: 100%;
  margin: 10px;
  background-color: white;
}

.sectionHeading {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 5px;
}

.searchRow {
  margin: 10px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  border-bottom: 1px dotted rgba(20, 195, 155, 0.2);
}

.industry {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
  width: 50%;
  text-align: right;
}

.query {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  width: 50%;
  align-items: flex-start;
}

.landingBrandname {
  font-size: 3rem;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.sectionBrandName {
  margin-top: 20%;
}

.highlight {
  white-space: pre-line;
}

.highlight > span::first-letter {
  background-color: white;
  color: black;
  padding: 0 5px;
}

.brandNamePara {
  font-size: 1.3rem;
}

.searchBar {
  width: 600px;
  height: 42px;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(20, 195, 155, 0.06);
  /* background-color: #020b26;
  color: white; */
  padding-left: 15px;
  margin-top: 20px;
  border: 0.5px solid black;
  font-size: 1rem;
}

.searchBar::placeholder {
  color: black;
}

.landingSections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 800px;  */
  padding-right: 5%;
  padding-left: 15%;
}

.landingSection2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 800px;  */
  padding-right: 5%;
  padding-left: 15%;
}

.landingSection3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 800px; */
  padding-right: 8%;
  padding-left: 15%;
}

.landingRightSections {
  width: 95%;
  margin-left: 7%;
}

.landingTechnologyText {
  text-align: center;
  margin-top: 20px;
}

.lastLandingText {
  text-align: center;
}

.lastLandingText p {
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  width: 80%;
  margin-left: 120px;
  margin-top: -50px;
}

.pageBanner {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 30px;
  padding: 50px;
  background-color: #f4f2ec;
}

.researchTab {
  margin-top: 30px;
  font-weight: 500;
  margin-left: 52px;
  background-color: white;
  color: #020b26;
  width: 23%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.researchTabText {
  font-size: 0.8rem;
  margin-left: 7px;
}

.researchSpan {
  text-align: right;
  margin-top: -25px;
  width: 100%;
  font-size: 1rem;
  margin-left: 120px;
}

.mapText {
  width: 70%;
  margin-left: 50px;
  height: 130px;
  margin-top: 160px;
  font-size: 1.3rem;
  font-weight: 600;
}

.map {
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landingResearch {
  background-color: #020b26;
  color: white;
  height: 450px;
  margin-top: 50px;
}

.landingResearchStats {
  background-color: #020b26;
  color: white;
  height: "200px";
  margin-left: 0;
  margin-right: 0;
  margin-top: 0px;
  margin-bottom: 50px;
}

.landingDonuts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.column {
  border-right: 1px solid #ffffff;
  padding-right: 15px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column:last-child {
  border-right: none;
}

.countryData {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 150px;
}

.countryDataNumber {
  text-align: center;
  font-size: 1.3rem;
  margin-top: 20px;
}

.countryDataText {
  text-align: center;
  font-size: 1rem;
  margin-top: 50px;
}

.researchYear {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 150px;
}

.researchYearNumber {
  text-align: center;
  font-size: 1.3rem;
  margin-top: 20px;
}

.researchYearText {
  text-align: center;
  font-size: 1rem;
  margin-top: 50px;
}

.companyFiling {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 150px;
}

.companyFilingNumber {
  text-align: center;
  font-size: 1.3rem;
  margin-top: 20px;
}

.companyFilingText {
  text-align: center;
  font-size: 1rem;
  margin-top: 50px;
}

.upperStrip {
  background-color: #f4f2ec;
  height: 40px;
  color: black;
  margin-top: 50px;
  margin-bottom: 50px;
}

.upperStripText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding-top: 12px;
  font-weight: bold;
}

.lowerStrip {
  background-color: #f4f2ec;
  height: 50px;
  color: black;
  margin-bottom: 50px;
}

.cardHeading {
  align-items: start;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
  display: block;
  font-size: 1.3rem;
}

.cardContainer {
  width: fit-content;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 15px;
  margin-right: 20px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f4f4f4;
}

.cardWrapper {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}

.card {
  display: inline-block;
  width: 240px;
  height: 240px;
  margin-right: 100px;
  background-color: white;
  border: 1px solid white;
  border-radius: 30px;
  position: relative;
}

.card-body {
  top: 30px;
  position: relative;
}

.cardTitle {
  font-size: 1.3rem;
  margin-top: 25px;
  text-align: left;
  font-weight: 600;
  margin-left: 20px;
  color: #020b26;
}

.cardText {
  font-size: 1rem;
  margin-top: 30px;
  margin-left: 20px;
  text-align: left;
}

.additionalInfo {
  text-align: left;
  position: absolute;
  top: 55%;
  margin-left: 20px;
  margin-right: 10px;
}

.cardContainer::-webkit-scrollbar {
  width: auto;
  height: 10px;
  margin-left: 50px;
}

.cardContainer::-webkit-scrollbar-track {
  background-color: #f4f2ec;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 10px;
}

.cardContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.cardContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.viewButton {
  position: absolute;
  top: 85%;
  margin-left: 20px;
  background-color: #f4f2ec;
  color: black;
  border-color: #f4f2ec;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 0.8rem;
}

.queryHeading {
  margin-top: 150px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 1.3rem;
}

.belowQueryHeading {
  width: 30%;
  margin-left: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(-50%);
}

.dyrTextLanding {
  font-size: 1.3rem;
}

.custom-table {
  width: 93%;
  border-collapse: collapse;
  margin-left: 52px;
  margin-bottom: 30px;
}

.custom-table thead {
  position: sticky;
  top: 0;
  background-color: #020b26;
  z-index: 1;
}

.custom-table th,
.custom-table td {
  padding: 16px;
  text-align: center;
  border: none;
}

.custom-table thead,
.custom-table tr {
  height: 50px;
  text-align: center;
}

.custom-table thead {
  background-color: #020b26;
  color: white;
}

.custom-table th,
tr {
  font-size: 1rem;
  font-weight: lighter;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

.custom-table tbody tr:hover {
  background-color: #f4f2ec;
  color: black;
}

.afterTableBanner {
  margin-bottom: 5px;
  margin-top: 20px;
  background-color: #020b26;
  color: white;
  height: 150px;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  text-align: center;
}

.tableBannerText {
  /* margin-left: 80px; */
  /* margin-top: 20px; */
  font-size: 1.3rem;
  text-align: center;
  align-items: center;
  line-height: 1.2;
  /* padding-top: 40px; */
  width: 65%;
}

.afterMapBanner {
  align-items: center;
  margin-bottom: 5px;
  /* margin-top: 20px; */
  background-color: #020b26;
  color: white;
  height: 150px;
  display: flex;
  justify-content: center;
}

.mapBannerText {
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.2;
  width: 60%;
}

.underBanner {
  margin-left: 285px;
  margin-top: 10px;
}

.bordered-table {
  border-collapse: collapse;
}

.bordered-table {
  border: 1px solid #6b859e;
}

.landingLastBanner {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 210px;
  background-color: #020b26;
  color: white;
  width: 100%;
}

.landingPara {
  margin-top: 20px;
}

.landingForstats {
  margin-left: 0;
  text-align: center;
}

.landingResearchAreaChart {
  margin-left: 80px;
  margin-top: 20px;
  width: 90%;
}

.landingGetAccess {
  font-size: 1rem;
  font-weight: bold;
}

.landingBody3 {
  font-size: 1rem;
  font-weight: 400;
  margin: 6px;
}

.hrLine {
  margin-bottom: 50px;
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.landingReactText {
  text-align: left;
  font-size: 2rem;
  margin-top: 20px;
  margin-left: 100px;
  width: auto;
  line-height: 1.2;
}

.landingAccess {
  margin-left: 100px;
  margin-top: 30px;
}

.landingAccessText {
  font-size: 1rem;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  border: 1px solid black;
  color: black;
  margin-right: 10px;
  margin-top: 10px;
  display: inline-block;
  transition: color 0.2s ease-in-out;
}

.landingAccessText:hover {
  background-color: #020b26;
  color: white;
  cursor: pointer;
}

.list-container {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-left: 4%;
}

.list {
  padding: 0;
  text-align: left;
  margin: 0;
  margin-left: 2.5%;
  width: 25%;
}

.list li {
  margin-bottom: 10px;
  position: relative;
  font-size: 1rem;
  color: black;
  margin-left: 6%;
}

.list li::before {
  content: "\2022";
  color: black;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  position: absolute;
  left: 0;
}

.list li.heading {
  font-weight: bold;
}

.separator {
  margin: 0 10px;
  height: 13em;
  border-left: 1px solid rgb(199, 198, 198);
}

.getReport {
  margin-left: 40px;
  margin-top: 30px;
  margin-left: 6%;
}

.getReportText {
  font-size: 0.8rem;
  border-radius: 10px;
  padding: 8px;
  background-color: transparent;
  border: 1px solid black;
  color: black;
  margin-right: 10px;
  margin-top: 10px;
  display: inline-block;
  transition: color 0.2s ease-in-out;
}

.getReportText:hover {
  background-color: #020b26;
  color: white;
  cursor: pointer;
}

.landingLinePortfolio {
  width: 15%;
  margin-left: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(-50%);
}

.landingReact {
  margin-top: 5%;
  margin-left: 95px;
  font-size: 0.8rem;
  font-weight: 500;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.landingSignupPage {
  background: linear-gradient(to right, rgb(16, 16, 16), rgb(13, 39, 238));
  /* background-image: url("/public/output-onlinegiftools.gif"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 670px; */
  margin: 0px;
  padding: 0px;
}

.landingSignupContainer {
  width: 50%;
  margin-top: 10px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.landingSignupContainer:hover {
  transform: perspective(800px) rotateX(0deg);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.landingSignupContainer::after {
  content: "";
  display: table;
  clear: both;
}

.paraReport {
  font-weight: bold;
  margin-top: 20px;
  margin-left: 7%;
  font-size: 1rem;
}

.companyRow {
  margin-top: 20px;
}

.scrolling-containerNew {
  display: flex;
  overflow-x: auto;
  padding: 10px;
}

.card-wrapperInsightNew {
  flex: 0 0 auto;
  margin-right: 10px;
}

.cardNew {
  border: 1px solid #dbdbde;
  margin: 20px;
  width: 300px;
  border-radius: 15px;
  display: inline-block;
    height: 130px;
}

.cardBodyNew{
  top: 0px;
  position: relative;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.card-wrapperInsight {
  min-width: 300px;
  margin-right: 10px;
}

.vertical-line-container {
  display: flex;
  align-items: center;
}

.line {
  width: 1px;
  height: 100px;
  background-color: white;
  margin: 0 10px;
}

.circles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
}

.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
}

/* Keyframes for scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.partnerColumn{
  margin-top: 0;
}

/* New signup */

.wrapper{
  overflow: hidden;
  max-width: 391px;
  margin-left: 37%;
  background: #fff;
  padding: 15px 30px;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0,0,0,0.1);
}
.wrapper .title-text{
  display: flex;
  width: 100%;
}
.wrapper .title{
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
.wrapper .slide-controls{
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 15px;
}
.slide-controls .slide{
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}
.slide-controls label.signup{
  color: #000;
}
.slide-controls .slider-tab{
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 15px;
  background: -webkit-linear-gradient(left,#003366,#004080,#0059b3
, #0073e6);
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
input[type="radio"]{
  display: none;
}
#signup:checked ~ .slider-tab{
  left: 50%;
}
#signup:checked ~ label.signup{
  color: #fff;
  cursor: default;
  user-select: none;
}
#signup:checked ~ label.login{
  color: #000;
}
#login:checked ~ label.signup{
  color: #000;
}
#login:checked ~ label.login{
  cursor: default;
  user-select: none;
}
.wrapper .form-container{
  width: 100%;
  overflow: hidden;
}
.form-container .form-inner{
  display: flex;
  width: 200%;
}
.form-container .form-inner form{
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
.form-inner form .field{
  height: 50px;
  width: 100%;
  margin-top: 20px;
}
.form-inner form .field input{
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 15px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}
.form-inner form .field input:focus{
  border-color: #1a75ff;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}
.form-inner form .field input::placeholder{
  color: #999;
  transition: all 0.3s ease;
}
form .field input:focus::placeholder{
  color: #1a75ff;
}
.form-inner form .pass-link{
  margin-top: 5px;
}
.form-inner form .signup-link{
  text-align: center;
  margin-top: 30px;
}
.form-inner form .pass-link a,
.form-inner form .signup-link a{
  color: #1a75ff;
  text-decoration: none;
}
.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover{
  text-decoration: underline;
}
form .btn{
  height: 50px;
  width: 100%;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
form .btn .btn-layer{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right,#003366,#004080,#0059b3
, #0073e6);
  border-radius: 15px;
  transition: all 0.4s ease;;
}
form .btn:hover .btn-layer{
  left: 0;
}
form .btn input[type="submit"]{
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  color: #fff;
  padding-left: 0;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .landingPage {
    height: 250px;
    margin: 0px;
    padding: 0px;
  }

  .researchText {
    font-size: 1.5rem;
  }

  .dyrTextLanding {
    font-size: 0.8rem;
  }

  .researchTextLast {
    font-size: 1.5rem;
  }

  .elaboratedText {
    font-size: 1rem;
  }

  .elaboratedTextLast {
    font-size: 1rem;
  }

  .landingFirstBanner {
    width: 90%;
    height: auto;
    margin-top: 90px;
  }

  .landingResearchStats {
    height: auto;
  }

  .landingResearch {
    height: auto;
  }

  .mapText {
    margin-top: 50px;
    width: 95%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .map {
    margin-top: 30px;
    width: 95%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .countryDataText,
  .researchYearText,
  .companyFilingText {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .researchYearNumber,
  .companyFilingNumber {
    margin-top: 0px;
  }

  .landingLastBanner {
    height: 120px;
    margin-bottom: 30px;
  }

  .queryHeading {
    margin-top: 110px;
    font-size: 0.8rem;
  }

  .landingSections {
    height: auto;
    padding-left: 6%;
  }

  .landingSection2 {
    height: auto;
    padding-right: 1%;
    padding-left: 5%;
  }

  .landingSection3 {
    height: auto;
    padding-right: 1%;
    padding-left: 5%;
  }

  .landingBrandname {
    margin-top: -30px;
    margin-left: 7px;
    font-size: 2rem;
  }

  .sectionBrandName {
    margin-top: 20px;
    font-size: 1.5rem;
  }

  .landingPara {
    font-size: 0.8rem;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 7px;
  }

  .queryHeading {
    font-size: 0.8rem;
  }

  .belowQueryHeading {
    width: 70%;
    margin-left: 15%;
  }

  .landingResearchAreaChart {
    margin-left: 20px;
  }

  .lastLandingText p {
    margin-left: 0px;
    font-size: 0.8rem;
    margin-top: -15px;
    width: 100%;
  }

  .landingDonuts {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .landingRightSections {
    margin-left: 0;
  }

  .landingBody3 {
    font-size: 0.8rem;
  }

  .list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .landingLinePortfolio {
    width: 60%;
  }

  .list {
    width: 80%;
    margin-left: 5%;
  }

  .list li {
    margin-left: 7%;
  }

  .separator {
    margin: 0px;
    margin-top: 10px;
    margin-left: 15px;
    width: 13em;
    border-bottom: 0.5px solid rgb(199, 198, 198);
    border-left: none;
    height: 1px;
  }

  .landingReactText {
    margin-left: 5%;
    font-size: 1.5rem;
  }

  .landingAccess {
    margin-left: 5%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .landingAccessText {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 0.8rem;
    border-radius: 5px;
  }

  .landingReact {
    margin-left: 3%;
  }

  .landingSignupPage, .height-login{
    /* height: 700px; */
  }

  .landingSignupContainer {
    height: auto;
    margin-left: 25px;
    width: 90%;
    margin-top: 20px;
  }

  .companyRow {
    margin-top: 60%;
  }

  .partnerColumn{
    margin-top: 0;
  }

  .wrapper{
    margin-left: 0px;
  }
}

@media (max-width: 576px) {
  .researchText {
    font-size: 1.2rem;
  }

  .researchTextLast {
    font-size: 1.2rem;
  }

  .elaboratedText {
    font-size: 1rem;
  }

  .elaboratedTextLast {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .custom-table {
    width: 90%;
    margin-left: 5px;
  }

  .custom-table thead,
  .custom-table th,
  .custom-table td {
    font-size: 0.9rem;
  }

  .custom-table thead,
  .custom-table tr {
    height: 30px;
  }

  .custom-table tbody tr:hover {
    background-color: #f4f2ec;
    color: black;
  }
}

/* Media query for extra small screens */
@media (max-width: 375px) {
  .custom-table {
    width: 80%;
    margin-left: 0;
  }

  .custom-table thead,
  .custom-table th,
  .custom-table td {
    font-size: 0.8rem;
  }

  .custom-table thead,
  .custom-table tr {
    height: 25px;
  }
}

/* Media query for tiny screens */
@media (max-width: 320px) {
  .custom-table {
    width: 70%;
    margin-left: 0;
  }

  .custom-table thead,
  .custom-table th,
  .custom-table td {
    font-size: 0.7rem;
  }

  .custom-table thead,
  .custom-table tr {
    height: 20px;
  }
}

@media (max-width: 768px) {
  .tableBannerText {
    font-size: 1rem;
    padding: 10px;
    width: 90%;
  }
}

/* Media query for extra small screens */
@media (max-width: 576px) {
  .tableBannerText {
    font-size: 1rem;
    padding: 10px;
    width: 95%;
  }
}

@media (max-width: 768px) {
  .tableBannerText {
    font-size: 1rem;
    padding: 10px;
    width: 90%;
  }
}

/* Media query for extra small screens */
@media (max-width: 576px) {
  .tableBannerText {
    font-size: 1rem;
    padding: 10px;
    width: 95%;
  }
}

@media (max-width: 768px) {
  .researchTab {
    width: 35%;
    margin-left: 10px;
  }

  .researchTabText {
    margin-left: 15px;
  }

  .researchSpan {
    margin-left: 0px;
    text-align: center;
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .column {
    border-right: none;
    border-bottom: 1px solid #ffffff;
    padding: 15px;
    height: auto;
  }

  .column:last-child {
    border-bottom: none;
  }

  .countryData,
  .researchYear,
  .companyFiling {
    margin-top: 20px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .afterMapBanner {
    height: auto;
    padding: 20px;
  }

  .mapBannerText {
    width: 100%;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .researchText {
    text-align: left;
    margin-left: 65px;
  }

  .researchTextLast {
    text-align: left;
    margin-left: 55px;
    margin-top: 20px;
  }

  .underBanner {
    margin-left: 16px;
    width: 100%;
    font-size: 0.8rem;
  }

  .elaboratedText {
    text-align: right;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    font-size: 1rem;
  }

  .elaboratedTextLast {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 5px;
    margin-left: 0px;
    font-size: 1rem;
    height: auto;
  }

  .landingSignupHyper {
    font-size: 1rem;
    width: 40%;
    text-align: left;
  }

  .landingStats {
    font-size: 0.6rem;
  }
}
