/* .customTableContainerFull {
    margin: 20px;
  }
  
  .tableHeadingFull {
    background-color: white;
    height: 30px;
    margin-left: 17px;
    font-size: 0.8rem;
    font-weight: bold;
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .mainTableFull {
    display: flex;
    flex-direction: column;
  }
  
  .mainTableFull thead{
    background-color: #020b26;
    color: white;
    width: 97%;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 8px;
  }

  .mainTableFull thead tr {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10%;
  }
  
  .mainTableFull thead th {
    font-weight: normal;
  }
  
 
  
  .mainTableFull tbody tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin-left: 25px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    margin-top: 20px;
    cursor: pointer;
    transition: transform 0.1s ease;
  }
  
  .mainTableFull tbody tr:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .tableFirstColumnFull {
    width: 25%;
    text-align: left;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .tableSecondColumnFull {
    width: 20%;
    text-align: center;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .tableThirdColumnFull {
    width: 20%;
    text-align: center;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .tableFourthColumnFull {
    width: 20%;
    text-align: center;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .tableFifthColumnFull {
    width: 15%;
    text-align: right;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  
  .customSkeleton {
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .tableHeading {
      margin-left: 11px;
    }
  
    .mainTable thead {
      margin-left: 5px;
    }
  
    .mainTable tbody tr {
      margin-left: 15px;
    }
  
    .tableFirstColumn, .tableSecondColumn, .tableThirdColumn, .tableFourthColumn, .tableFifthColumn {
      font-size: 0.8rem;
    }
  
    .mainTable thead th {
      font-size: 0.8rem;
    }
  }
   */

/* Container for the table */
.table-container {
    margin: 0px 20px;
    padding: 20px;
    background: transparent;
    max-width: auto;
    border-radius: 8px;
    /* border: 0.5px solid lightgray; */
  }
  
  .table-heading {
    color: #333;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .vibrant-table {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border-collapse: separate; 
    border-spacing: 0; 
    border: 0.5px solid lightgray;
  }
  
  .vibrant-table thead {
    background: linear-gradient(to right, rgb(208, 121, 223), rgb(24, 52, 221));
    color: white;
  }
  
  .vibrant-table thead th {
    padding: 16px 20px;
    font-weight: 700;
    text-align: center;
    font-size: 1rem;
    border-bottom: 2px solid #000;
  }
  
  .vibrant-table tbody tr:nth-child(odd) {
    background: white;
  }
  
  .vibrant-table tbody tr:nth-child(even) {
    background: #f1f0f0;
  }
  
  .vibrant-table tbody tr {
    border-bottom: 1px solid lightgray;
  }
  
  .vibrant-table tbody tr:hover {
    color: #333;
    transform: scale(1.01);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .vibrant-table td,
  .vibrant-table th {
    padding: 14px 18px;
    border-right: 1px solid lightgray; 
  }
  
  .vibrant-table td:last-child,
  .vibrant-table th:last-child {
    border-right: none; 
  }
  
  .vibrant-table td:first-child {
    text-align: left;
  }
  
  .vibrant-table td {
    text-align: center;
  }

  .show-more-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .show-more-button {
    background: linear-gradient(to right, rgb(16 16 16), rgb(13 39 238));
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .show-more-button:hover {
    background-color: #031d44;
    transform: scale(1.05);
  }

  .labelTechnology {
    font-size: 1rem;
}

.selectTechnology {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
}

.technology-name {
    margin-top: 20px;
    margin-left: 15px;
    font-weight: bold;
    font-size: 1rem;
}

.dropdownTechnology2{
    margin: 0px 0px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

  /* Responsive design */
  @media (max-width: 768px) {
    .table-heading {
      font-size: 1.4rem;
    }

    .table-container {
        margin: 0px 10px;
        padding: 10px;
        background: transparent;
        max-width: auto;
      }
  
    .vibrant-table thead th {
      font-size: 0.7rem;
      padding: 10px 12px;
    }
  
    .vibrant-table td,
    .vibrant-table th {
      padding: 8px 10px;
      font-size: 0.7rem;
    }
  
    .vibrant-table {
      font-size: 0.9rem;
    }

    .show-more-button {
        border-radius: 6px;
        padding: 8px 12px;
        font-size: 0.7rem;
      }
  }
  
 
  
  
  
  
  