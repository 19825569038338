.organizationTag{
    margin-top: 10px;
    font-size: 2rem;
    margin-left: 35px;
    width: auto;
    font-weight: bold;
}

.organizationText{
    width: 90%;
    margin-left: 35px;
    height: auto;
    font-size: 0.8rem;
    margin-top: 20px;
    line-height: 1.3;
    margin-top: 20px;
    border-bottom: 0.5px solid rgb(199, 198, 198);
    height: 40px;
}