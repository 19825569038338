.inventor-profile {
    width: 80%;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .details-card, .statistics-cards {
    margin-bottom: 20px;
    padding: 20px;
  }
  
  /* .details-card h2, .stat-card h2 {
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 15px;
  } */
  
  .details-card p, .stat-card p {
    margin: 10px 0;
  }
  
  .statistics-cards {
    display: flex;
    justify-content: space-between;
  }
  
  .stat-card {
    flex: 1;
    margin: 0 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .stat-card p {
    font-size: 1.2em;
    font-weight: bold;
  }

  .stat-card h2 {
    color:  #707579;
  }

  /* .container {
    display: flex;
    justify-content: space-between; 
  } */

  /* .striped-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    font-family: 'Arial, sans-serif';
  }
  
  .striped-table th, .striped-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .striped-table th {
    background: #333;
    color: white;
    font-weight: bold;
  }
  
  .striped-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .striped-table tbody tr:hover {
    background-color: #e1f5fe;
    transition: background 0.3s;
  }
  
  .striped-table tbody tr:hover td {
    color: #007bb6;
    font-weight: bold;
  } */


  .striped-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    font-family: 'Arial, sans-serif';
}

.striped-table th, .striped-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.striped-table td {
  cursor: pointer;
}

.striped-table th {
    background: #007bb6; /* Darker blue for header background */
    color: white;
    font-weight: bold;
}

.striped-table tbody tr:nth-child(odd) {
    background-color: #f1f7fc; /* Light blue for odd rows */
}

.striped-table tbody tr:nth-child(even) {
    background-color: #eaf2f8; /* Slightly darker blue for even rows */
}

.striped-table tbody tr:hover {
    background-color: #cfe9fa; /* Brighter blue for hover effect */
    transition: background 0.3s;
}

.striped-table tbody tr:hover td {
    color: #005b99; 
    font-weight: bold;
}

.striped-table tbody tr td {
    transition: color 0.3s;
}

.striped-table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}
