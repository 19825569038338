.landscapeTag {
    margin-top: 30px;
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 25px;
    width: 25%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bolder;
  }
  
  
  .globalTechText{
    margin-top: 30px;
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 25px;
    width: 38%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bolder;
  }
  
  
  .countryContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 40px;
  }
  
  
  .countryBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    background-color: #f7f8fb;
    padding: 20px;
    font-size: 0.8rem;
    border-radius: 10px;
  }
  