.newTechnologyTableheading{
  background-color: white;
  width: auto; 
  height: 30px; 
  margin-left: 17px; 
  font-size: 0.8rem; 
  font-weight: bold; 
  padding: 0px; 
  color: black; 
  display: flex; 
  align-items: center; 
  justify-content: flex-start; 
}

.mainTable {
    display: flex;
    flex-direction: column;
  }

.mainTable thead{
  background-color: #020b26;
  color: white;
  width: 94%;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 8px;
}

.globalTable thead{
  background-color: #020b26;
  color: white;
  width: 90%;
  margin-left: 25px;
  margin-top: 10px;
  border-radius: 8px;
}

.mainTable thead tr{
  margin-top: 5px;
  margin-bottom: 5px;
}

.mainTable tr{
  display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-left: 25px;
}

.globalTable tr{
  display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin-left: 45px;
}

.rowTable{
    display: flex;
  justify-content: space-between;
  width: calc(100% - 25px);
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 25px;
  cursor: pointer;
  transition: transform 0.1s ease; 
}

.rowTable:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tableFirstColumn {
    width: 73%;
    text-align: left;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  
  .tableSecondColumn {
    width: 27%;
    text-align: right;
    padding: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reportCPC{
    width: 95%;
    margin-top: 30px;
    margin-left: 4.5%;
  }

  .reportTableFirst{
    width: 10%;
    text-align: left;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .reportTableSecond{
    width: 40%;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reportTableThird{
    width: 10%;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reportTableFourth{
    width: 30%;
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reportTableFifth{
    width: 5%;
    text-align: right;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content:end;
  }

  .customSkeleton{
    margin-bottom: 20px;
  }

  .reportTableSecondSkeleton{
    margin-bottom: 20px;
    margin-left: 350px;
  }

  .recentClaimSkeleton{
    margin-left: 80px;
    margin-bottom: 20px;
  }

  .theadBestYear{
    margin-right: -5rem;
  }

  @media (max-width: 768px) {
    .newTechnologyTableheading{
    margin-left: 11px;
    }

    .mainTable thead{
      margin-left: 5px;
    }

    .mainTable tr{
      margin-left: 15px;
    }

    .tableFirstColumn{
      font-size: 0.8rem;
    }

    .tableSecondColumn{
      font-size: 0.8rem;
    }

    .mainTable thead th{
      font-size: 0.8rem;
    }

    .mainTable tbody tr{
      margin-left: 5px;
      
    }

    .globalTable{
      margin-left: 20px;
    }

    .recentClaimSkeleton{
      margin-left: 20px;
    }

    .theadBestYear{
      margin-right: 2rem;
    }
  }