/* .patent-list{
    height: 600px;
    overflow-y: scroll;
} */

.patents-section{
    width: 93%;
    margin-top: 10px;
    margin-left: 25px;
    background-color: transparent;
    border: 1px solid #dbdbde;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 30px;
}

.patents-section:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
}

.patentsContent{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
}

.headingText{
    font-size: 1rem;
    font-weight: bold;
}

.details-patent{
    font-size: 0.7rem;
    color: #838c95;
    margin-top: 20px;
}

.patentDescription{
    font-size: 0.8rem;
    margin-top: 20px;
}

.pagination{
    margin-left: 35px;
}

.paginationPrevious{
    border: none;
    font-size: 0.7rem;
}

.paginationNext{
    border: none;
    font-size: 0.7rem;
}

.paginationPage {
    background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
    cursor: pointer;
    margin-right: 5px;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 0.7rem;
  }

  .paginationPage.active {
    background-color: #020b26;
    color: #fff;
    border: 1px solid #020b26;
  }

  .patent-list{
    margin-bottom: 30px;
  }

  .downloadListButton{
    border-radius: 5px;
    padding: 4px;
    margin-left: 60%;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    color: #4007a2;
  }

  @media (max-width: 768px) {
    .patents-section{
    margin-left: 15px;
    }
    .downloadListButton{
        padding: 4px;
    margin-left: 15%;
    font-size: 0.875rem;
    }
}