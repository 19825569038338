.tableSection {
  margin: 25px;
  padding: 20px;
  border: 0.5px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.tableSection:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.tableTitle {
  margin-bottom: 20px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

.tableNovel {
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: #333;
}

.tableFeature {
  font-weight: bold;
  color: #333;
}

.tableFeatureli {
  margin-bottom: 10px;
  list-style-type: disc;
  line-height: 1.2;
  color: #444;
}

.tableButton {
  background-color: #f4f2ec;
  color: black;
  border: 1px solid #f4f2ec;
  font-size: 0.8rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  width: max-content;
}

.tableButton:hover {
  background-color: #020b26;
}

.tableOYIIDF {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.tableOYIIDF th,
.tableOYIIDF td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tableOYIIDF th {
  background-color: #020b26;
  font-weight: bold;
  color: #f9f9f9;
}

.tableOYIIDF tbody tr:hover {
  background-color: #f9f9f9;
}

.fileMoreIDF{
  position: relative;
  top: 10px;
  text-align: center;
  height: 25px;
  display: block;
  text-decoration: none;
  font-weight: bolder;
  text-decoration: underline;
}

@media (max-width: 786px){
    .idfButton{
        margin-left: 25%;
    }
}
