.profileSide{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
}

.companyButton{
    font-size: 0.6rem;
    border-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f4f4ec;
    border: 1px solid #f4f4ec;
    color: black;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.technologyButton{
    font-size: 0.6rem;
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f4f4ec;
    border: 1px solid #f4f4ec;
    color: black;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.dyrButton{
    font-size: 0.6rem;
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f4f4ec;
    border: 1px solid #f4f4ec;
    color: black;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.foryouButton{
  font-size: 0.6rem;
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f4f4ec;
    border: 1px solid #f4f4ec;
    color: black;
    margin-right: 20px;
    margin-top: 10px;
    cursor: pointer;
}

.companyButton.selected,
.companyButton:hover {
  background-color: #020b26;
  color: white;
  border: 1px solid #f4f4ec;
}

.technologyButton.selected,
.technologyButton:hover {
    background-color: #020b26;
    color: white;
}

.dyrButton.selected,
.dyrButton:hover {
    background-color: #020b26;
    color: white;
}

.foryouButton.selected,
.foryouButton:hover{
  background-color: #020b26;
  color: white;
}

.arrow{
    padding-bottom: 5px;
    padding-right: 7px;
}

.personalize {
  font-size: 0.7rem;
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #0B57D0;
  border: 1px solid #0B57D0;
  color: white;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.personalize:hover {
  background-color: #020b26;
  color: white;
}

.burger-menu {
  display: none;
}

.menu {
  display: none;
}

.companyButton,
.technologyButton,
.dyrButton,
.personalizeHeaderSignUp {
  background-color: #f4f4ec;
  border: 1px solid #f4f4ec;
  color: black;
  cursor: pointer;
}

.companyButton.selected,
.companyButton:hover,
.technologyButton.selected,
.technologyButton:hover,
.dyrButton.selected,
.dyrButton:hover,
.personalizeHeaderSignUp:hover {
  background-color: #020b26;
  color: white;
}


.whiteText {
  color: white;
}

.whiteText:hover{
  color: white;
}

.whiteUserText{
  color: white;
}

.whiteBurger{
  color: white;
}

.blackUserText {
  color: black; 
}

.blackText {
  color: black; 
}

.blackBurger {
  color: black; 
}

.signUpText{
  color: white;
}

.logOut:hover{
  background-color: #f4f2ec;
  color: black;
  border-radius: 5px;
}

.forYou:hover{
  background-color: #f4f2ec;
  color: black;
  border-radius: 5px;
}

.userProfile{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
}

.profileSide .companyButton,
.profileSide .technologyButton,
.profileSide .dyrButton,
.profileSide .foryouButton {
  display: none; 
}

.profileSide.menu-open .companyButton,
.profileSide.menu-open .technologyButton,
.profileSide.menu-open .dyrButton,
.profileSide.menu-open .foryouButton {
  display: block; 
}

  @media screen and (max-width: 768px) {
    .profileSide {
      flex-direction: column;
      align-items: center; 
      align-items: end;
      justify-content: end;
    }
    .personalize{
      flex-direction: column;
      align-items: center; 
      top: 5px;
      margin-top: 0px;
      margin-right: -10px;
    }
    .technologyButton{
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .dyrButton{
      margin-right: 12px;
      margin-bottom: 10px;
    width: auto;
    display: flex;
    }
    .foryouButton{
      margin-top: 0px;
      margin-bottom: 10px;
    }
    .menu{
      display: block;
      /* color: white; */
    }

    .userProfile{
      margin-right: -10px;
    }
  }