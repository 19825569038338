.patents {
  width: 40%;
  margin-top: 1vw;
  margin-left: 9.5vw;
  background-color: white;
  border: 1px solid #dbdbde;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 30px;
}

.tableHead {
  font-size: 1.3rem;
  margin-left: 1vw;
  margin-top: -8px;
}

.Widget_widget__16nWC {
  border-radius: 8px;
  height: 550px;
}

.widget-p-md {
  padding: 24px;
}

.headline-3 {
  font-size: 1rem;
  color: #020b26;
}

.justify-content-between {
  justify-content: space-between !important;
}

.d-flex {
  display: flex !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.Dashboard_widgetBlock__Myojl {
  padding: 10px 0;
  background-color: #f7f8fb;
  border-radius: 8px;
}

.Dashboard_widgetBody__3yEc5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}
img,
svg {
  vertical-align: middle;
}
img {
  border-style: none;
}

.flex-column {
  flex-direction: column !important;
}

.body-2 {
  font-size: 1rem;
  margin: 6px;
}

.body-3 {
  font-size: 0.8rem;
  margin: 6px;
}

.patentTableTitle {
  color: #020b26;
}

.view {
  border-radius: 5px;
  background-color: #020b26;
  border: 0.5px solid #020b26;
  color: white;
  cursor: pointer;
  padding: 4px;
}

p {
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.muted {
  color: #6b859e;
}

.body-3,
.label {
  font-weight: 400;
}

.landingData {
  /* background-color: #f4f2ec; */
  color: black;
}

.textualData {
  justify-content: center;
  text-align: center;
}

.heading {
  font-size: 1rem;
}

.forLanding {
  font-size: 0.8rem;
}

.landingData{
  width: 450px;
}

.landingData:hover{
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.TableRow:hover {
    background-color: #020b26;
  }
  
  .TableRow:hover .body-2,
  .TableRow:hover .body-3 {
    color: white;
  }


  .patentTableContainer {
    height: 550px;
    overflow-y: auto;
    width: 122%;
    margin-top: 20px;
  }
  
  .tableContainer {
    position: relative;
    height: 550px; 
    overflow-y: auto;
    width: 100%;
  }
  
  .custom-tablePatent thead {
    position: sticky;
    top: 0;
    background-color: #f0f0f0; 
    z-index: 1;
  }

.custom-tablePatent tr,td{
    font-size: 0.8rem;
    font-weight: lighter;
}

.custom-tablePatent thead th{
    font-weight: lighter;
    position: sticky;
    z-index: 1;
}

  .custom-tablePatent::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    margin-left: 50px;
  }
  
  .custom-tablePatent::-webkit-scrollbar-track {
    background-color: #F4F2EC;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 10px;
  }
  
  .custom-tablePatent::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  .custom-tablePatent::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .table-container {
     height: 550px;
    overflow-y: auto;
  }
  
  .custom-tablePatent {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  
  .custom-tablePatent th,
  .custom-tablePatent td {
    padding: 12px;
    text-align: center;
  }
  
  .custom-tablePatent thead th {
    position: sticky;
    background-color: #020b26;
    border-color: #020b26;
    color: white;
    z-index: 1;
  }

  .custom-tablePatent tbody tr:nth-child(odd){
    background-color: white;
  }
  
  .custom-tablePatent tbody tr:nth-child(even) {
    background-color: #f8f9fa;
  }

  .custom-tablePatent thead th:first-child {
    width: 40%; 
  }

  .custom-tablePatent tbody tr:hover{
    background-color: #F4F2EC;
  }
  
  .no-margin {
    margin-left: 0;
  }
