
.searchIcon{
    position: relative;
    top: 5px;
    right: 10px;
    cursor: pointer;
    margin-left: -20px;
}

.navigationText{
    font-size: 0.7rem;
    margin-left: 25px;
    margin-top: 10px;
}

.navigationSuggestions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -20px;
    margin-left: 90px;
}

.navigationSuggestions li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    width: auto;
    height: 30px;
    color: black;
    padding: 8px 16px;
    margin-right: 10px;
    background-color: #ffffff;
    border: 1.92px solid;
    border-color: #eff0f6;
    border-radius: 8px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
}

.navigationSuggestions li:hover{
    background-color: #020b26;
    color: white;
}

.columnItems{
    display: flex;
    flex-direction: row;
}

.searchBoxNew{
 margin-bottom: 10px;
}

@media (max-width: 768px) {
    .searchBar {
        padding-left: 20px; 
        font-size: 0.9rem;
        width: 340px;
    }
    .brandName{
        font-size: 1rem;
    }
    .sectionContainer{
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .suggestions li {
        font-size: 0.6rem;
        padding: 6px 12px;
        height: 32px;
        margin-right: 8px;
        margin-bottom: 8px;
    }
    
    .suggestions {
        margin-left: 25px; 
        max-width: calc(100% - 20px);
        overflow-x: auto; 
    }
}