@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
.searchResult {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  font-family: "Lato", sans-serif;
}

.forSearch {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.barSearch {
  width: 40vw;
  height: 42px;
  border-radius: 40px;
  padding-left: 30px;
  margin-top: 20px;
  border: 0.5px solid rgb(222, 219, 219);
  font-size: 1rem;
  z-index: 1;
}

.barSearch:hover {
  box-shadow: 0 15px 20px rgba(14, 14, 14, 0.06);
}

.suggestions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-left: 20px;
}

.suggestions li {
  display: inline-flex;
  align-items: center;
justify-content: center;
  font-size: 0.7rem;
width: auto;
  height: 40px;
color: black;
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #ffffff;
  border: 1.92px solid;
  border-color: #eff0f6;
  border-radius: 8px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.suggestions li:hover{
  background-color: #020b26;
  color: white;
}

.suggestions li.selected {
  background-color: #020b26;
  color: white;
}

.sectionContainer {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: right;
  margin-right: 10px;
  flex-grow: 1;
}

.sectionButton {
  margin-right: 20px;
  margin-left: 25px;
  cursor: pointer;
  font-size: 0.7rem;
  transition: all 0.1s ease-in-out;
}

.bold {
  font-weight: bold;
  padding: 10px 40px;
  transition: all 0.1s ease;
  box-shadow: 8px 8px 5px -3px #ccc;
  background-color: #f4f4ec;
}

.headingSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  background-color: #020b26;
  color: white;
  height: 40px;
}

.patentSection {
  margin-top: 50px;
}

.patentsTable {
  width: 100%;
  align-self: flex-start;
}

.forDataTag {
  margin-top: 30px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 52px;
  background-color: #020b26;
  color: white;
  width: 25%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dataTag {
  margin-left: 7px;
}

.summaryTag {
  margin-top: 30px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 17px;
  background-color: #020b26;
  color: white;
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.summaryTagText {
  margin-left: 7px;
}

.para {
  margin-top: 30px;
  font-size: 1.3rem;
  font-weight: 500px;
  margin-left: 25px;
  width: 85%;
  color: black;
}

.patentAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 22px;
  margin-right: 20px;
}

.patentItem {
  font-size: 0.7rem;
  margin-right: 20px;
  cursor: pointer;
}

.forStats {
  margin-top: 10px;
  margin-left: 30px;
}

.forStats p {
  font-size: 0.7rem;
}

.vertical-line {
  border-left: 1px solid #fff;
  height: 75%;
  width: 20px;
  margin: 70px 725px;
}

.analysis {
  width: 100%;
  height: 100%;
}

.numSummary {
  width: 100%;
  height: 100%;
  border-top: 1px solid #6b859e;
}

.numData {
  height: 30%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  color: #020b26;
}

.numText {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 30%;
  font-size: 1rem;
}

.ringSection {
  display: flex;
  flex-direction: column;
}

.ringText {
  text-align: center;
  font-size: 1.3rem;
  margin-top: 50px;
}

.analysisDesc {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-left: 20px;
  margin-top: 80px;
  line-height: 2.5;
}

.analysisDesc li {
  list-style: disc;
}

.descText {
  font-size: 1rem;
}

.afterPatents {
  border: none;
  border-top: 1px solid #ccc;
  width: 100%;
  margin-top: 150px;
}

.table-row {
  border-bottom: 2px solid #ccc;
}

.analysisSection {
  border-radius: 8px;
  margin-top: 790px;
  width: 85%;
  margin-left: 60px;
  height: 800px;
}

.afterSummaryBanner {
  height: 150px;
  background-color: #020b26;
  color: white;
  margin-top: 50px;
}

.summaryBannerText {
  text-align: center;
  width: 70%;
  margin-left: 220px;
  font-size: 1.3rem;
  padding-top: 35px;
  line-height: 1.2;
}

.marketTrendsTag {
  font-weight: 500;
  margin-left: 25px;
  background-color: #020b26;
  color: white;
  width: 30%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0px;
}

.forTrendsTag {
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 52px;
  background-color: #020b26;
  color: white;
  width: 35%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.trendsTag {
  margin-left: 7px;
  font-size: 0.8rem;
}

.insightsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin-bottom: 40px; */
  margin-top: 30px;
  padding: 50px;
  background-color: #020b26;
  color: white;
  height: 180px;
}

.custom-tableResult {
  width: 100%;
  border-collapse: collapse;
  margin-left: 52px;
  margin-top: 20px;
}

.custom-tableResult th,
.custom-tableResult td {
  padding: 12px;
  text-align: center;
  border: none;
  font-size: 1rem;
}

.custom-tableResult thead,
.custom-tableResult tr {
  height: 30px;
  text-align: center;
  font-size: 1rem;
  border: 1px solid white;
}

.custom-tableResult thead {
  background-color: #020b26;
  color: white;
}

/* .custom-tableResult tbody tr:nth-child(even) {
  background-color: #f8f9fa;
} */

.custom-tableResult tbody tr:hover {
  background-color: #f4f2ec;
  color: black;
}

.researchMapSearch {
  margin-top: 15px;
  margin-left: 25px;
  font-size: 1.3rem;
  width: 80%;
}

.citationMap {
  margin-left: 50px;
  width: 80%;
  height: auto;
  margin-bottom: 20px;
}

.accessBanner {
  height: 120px;
  background-color: #020b26;
  color: white;
  margin-top: 30px;
}

.accessBannerText {
  text-align: center;
  width: 70%;
  margin-left: 220px;
  font-size: 1.3rem;
  padding-top: 25px;
  line-height: 1.2;
}

.navTables {
  font-weight: 500;
  margin-left: 48px;
  background-color: #020b26;
  color: white;
  width: 35%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navTableText {
  margin-left: 7px;
  font-size: 0.8rem;
}

.navTechnologyTable {
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 48px;
  background-color: #020b26;
  color: white;
  width: 15%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navTechnologyText {
  margin-left: 7px;
}

.analysisText {
  display: flex;
  flex-direction: row;
  height: 530px;
  margin-left: 0px;
  margin-right: 0px;
  bottom: 750px;
  width: 100%;
}

.trendText {
  width: 90%;
  /* margin-left: 25px; */
  height: auto;
  font-size: 1.3rem;
  margin-top: 50px;
  line-height: 1.3;
}

.globalText{
  width: 90%;
  margin-left: 25px;
  height: auto;
  font-size: 1rem;
  margin-top: 50px;
  line-height: 1.3;
}

.globalStrong{
  font-size: 1rem;
}

.trendsSpan {
  padding-top: 20px;
  /* margin-left: 52px; */
  font-size: 1rem;
  text-align: center;
}

.patentPercent {
  margin-left: 52px;
  margin-top: 50px;
  font-size: 1rem;
}

.trendGraph {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.analysisGraphs {
  margin-bottom: 650px;
  display: flex;
  flex-direction: row;
}

.graph1 {
  width: 30%;
  height: 100%;
}

.graph2 {
  margin-left: 10px;
  width: 30%;
  top: 110px;
  position: relative;
}

.graph3 {
  width: 38%;
}

.afterTrends {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  width: 100%;
  display: block;
  top: 430px;
  position: relative;
}

.timeLineDiv {
  margin-top: 100px;
  width: 100%;
  background-color: #020b26;
  height: 800px;
}

.forNavTag {
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 52px;
  background-color: white;
  color: #020b26;
  width: 20%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navTag {
  margin-left: 7px;
}

.timeText {
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 60px;
  color: white;
  font-size: 1.3rem;
}

.spanTimeText {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
}

.afterTimeline {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  width: 100%;
  display: block;
  top: 650px;
  position: relative;
}

.mapNav {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  color: #020b26;
  height: 750px;
}

.simpleMap {
  margin-top: 50px;
  margin-left: 50px;
}

.mapNavTag {
  margin-top: 30px;
  z-index: 1;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 52px;
  background-color: #020b26;
  color: white;
  width: 45%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navTag {
  margin-left: 7px;
}

.mapSection {
  top: 500px;
  left: 0px;
}

.spanText {
  margin-top: 80px;
  height: 30px;
  width: 80%;
  display: block;
  font-size: 1rem;
  cursor: pointer;
}

.afterMapNav {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  width: 100%;
  display: block;
  margin-top: 750px;
}

.forIndustryTag {
  margin-top: 50px;
  z-index: 1;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 52px;
  background-color: white;
  color: #020b26;
  width: 45%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.industryTag {
  margin-left: 7px;
}

.navPlayers {
  margin-top: 50px;
  z-index: 1;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 52px;
  background-color: #020b26;
  color: white;
  width: 10%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navPlayersText {
  margin-left: 7px;
}

.navInnovators {
  margin-top: 50px;
  z-index: 1;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 52px;
  background-color: #020b26;
  color: white;
  width: 10%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navInnovatorsText {
  margin-left: 7px;
}

.bannerPlayers {
  background-color: #020b26;
  color: white;
  height: 150px;
  margin-top: 50px;
}

.bannerPlayersText {
  text-align: center;
  width: 70%;
  margin-left: 220px;
  font-size: 1.3rem;
  padding-top: 25px;
  line-height: 1.2;
}

.carouselContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
  white-space: nowrap;
  height: 150px;
  margin-top: 50px;
  margin-right: 30px;
}

.carouselContainer::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.carouselContainer::-webkit-scrollbar-track {
  background-color: transparent;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 50px;
}

.carouselContainer::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 50px;
}

.cpcTable {
  margin-left: 20px;
}

.nextNav {
  display: flex;
  flex-direction: column;
  height: 800px;
  background-color: #020b26;
  color: white;
  width: 100%;
}

.industryNav {
  width: 100%;
  margin-left: 50px;
  color: white;
}

.industryText {
  width: 70%;
  margin-left: 50px;
  height: 130px;
  margin-top: 300px;
  font-size: 1.3rem;
  font-weight: 600;
}

.spanIndustryText {
  margin-left: 50px;
  height: 30px;
  width: 70%;
  display: block;
  font-size: 1rem;
  cursor: pointer;
}

.companyText {
  margin-left: 90px;
  font-size: 1.3rem;
}

.searchLast {
  height: 50px;
  background-color: #020b26;
  color: white;
}

.searchLastText {
  text-align: center;
  width: 70%;
  margin-left: 220px;
  font-size: 1.3rem;
  padding-top: 18px;
  line-height: 1.2;
}

.borderRight{
  border-right: 0.5px solid rgb(199, 198, 198);
}

.trendsGraph1{
  margin-left: 20px;
  margin-right: 10px;
}

.trendsGraph2{
  margin-left: 20px;
  margin-right: 10px;
}

.horizontalBarChart{
  margin-left: 20px;
  margin-right: 10px;
}

.chart-heading h2{
  text-align: center;
  font-size: 0.8rem;
  margin-top: 0px;
}

.textActivity{
  margin-left: 25px;
  margin-top: 40px;
  font-size: 2rem;
  font-weight: bold;
}
 
.viewButtonCompany {
  background: linear-gradient(to right, rgb(16 16 16), rgb(13 39 238));
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  margin-right: 20px;
}

.statistics-cards2 div{
  font-size: 1.3rem;
  font-weight: bold;
}
.statistics-cards2 p{
  font-size:1rem;
}

/* new added */
.sectionHeading2{
  font-size: 0.8rem;
  font-weight: bold;
  margin-left: 24px;
  margin-top: 20px;
}

.keyPointsLI2{
  font-size: 0.8rem;
  color: black;
}
.keyPointsLI2 b{
  font-size: 0.8rem;
  color: black;
}

.keyPointsLI2:nth-child(odd){
  margin-bottom: 10px;
}

.paraData{
  font-size: 0.8rem;
  margin-left: 24px;
  margin-top: 10px;
}

.newDataGraphs{
  display: flex;
  flex-direction: row;
}

.mapDataTable{
  margin-top: 20%;
}

@media (max-width: 768px) {
  .forStats {
    margin-left: 22px;
  }

  .para{
    margin-left: 22px;
  }

  .borderRight{
    border-bottom: 0.5px solid rgb(199, 198, 198);
    border-right: none;
    margin-bottom: 10px;
  }

  .noBorderOnMobile {
    border-right: none; 
    border-bottom: none; 
}

  .globalStrong{
    font-size: 1rem;
  }

  .textActivity{
    margin-left: 25px;
    margin-top: 40px;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .custom-tableResult thead,
.custom-tableResult tr {
  height: auto;
  text-align: center;
  font-size: 0.8rem;
  border: 1px solid white;
}

.custom-tableResult th,
.custom-tableResult td {
  padding: 8px;
  text-align: center;
  border: none;
  font-size: 0.8rem;
}

.cpcTable {
  margin-left: 5px;
}

.sectionButton{
  margin-right: 10px;
  margin-left: 20px;
}

.bold{
  padding: 5px 20px;
}

.citationMap{
  margin-left: 20px;
  width: 100%;
}

.sectionHeading2{
  font-size: 0.7rem;
}

.keyPointsLI2{
  font-size: 0.7rem;
}

.statistics-cards2 div{
  font-size: 1rem;
  font-weight: bold;
}
.statistics-cards2 p{
  font-size:0.7rem;
}

.box-container{
  margin-top: 0px;
}

.paraData{
  font-size: 0.7rem;
  margin-left: 24px;
  margin-top: 10px;
}

.viewButtonCompany {
  background: linear-gradient(to right, rgb(16 16 16), rgb(13 39 238));
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.7rem;
  width: 35%;
}

.newDataGraphs{
  display: flex;
  flex-direction: column;
}

.mapDataTable{
  margin-top: 10%;
}

.patentsHeader{
  display: flex;
  flex-direction: row;
  /* margin-top: -25px; */
}

.patentAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-left: 25px;
  margin-right: 0px;
}
}

