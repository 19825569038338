.rightSection1 {
  background-image: url("/public/ai-bg.gif");
  background-position: center;
  background-repeat: no-repeat;
  height: 700px;
  margin: 0;
  padding: 0;
}

.rightText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.inventionText {
  font-size: 4rem;
  font-weight: lighter;
  color: white;
  text-align: left;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.inventionPara {
  color: white;
  margin-top: 30px;
  width: 70%;
  font-size: 1.3rem;
  margin-left: 16%;
}

.leftSection {
  position: relative;
  height: 62%;
}

.leftText {
  margin-top: 30%;
  margin-left: 10%;
}

.titlePatent {
  font-size: 1.3rem;
  font-weight: bold;
  width: 90%;
}

.name {
  font-size: 1.3rem;
  font-weight: bold;
  width: 90%;
  margin-top: 50px;
}

.userInfo {
  font-size: 1rem;
  width: 90%;
  margin-top: 10px;
}

.belowUserInfo {
  width: 30%;
  margin-left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(-50%);
  margin-top: 30px;
  height: 10px;
  background: linear-gradient(to right, #1834dd, #d079df);
}

.bottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  font-size: 0.8rem;
}

.belowBottomRight {
  width: 27%;
  top: 55%;
  margin-left: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(-50%);
  height: 0.5px;
}

.pageNavbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.forWhom {
  font-size: 0.7rem;
  font-weight: bold;
}

.logoReport {
  cursor: pointer;
  text-align: right;
  text-decoration: none;
  font-weight: bold;
  padding: 10px;
}

.aboutText {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 5%;
}

.title {
  font-size: 1rem;
  margin-left: 10%;
  margin-top: 3%;
}

.belowAbout {
  width: 30%;
  margin-left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(-50%);
}

.novelWrap {
  background-color: #020b26;
  color: white;
  padding: 15px;
  border-radius: 10px;
  margin-top: 5%;
  margin-left: 10%;
}

.novelPara {
  margin-left: 1%;
  font-weight: bold;
}

.novelElement {
  margin-left: 1%;
  font-size: 1.2rem;
  margin-top: 20px;
  line-height: 1.4;
}

.paraHeading {
  margin-left: 10%;
  margin-top: 5%;
  font-weight: bold;
}

.paraInvention {
  margin-left: 10%;
  font-size: 1.2rem;
  margin-top: 20px;
  line-height: 1.4;
  width: 80%;
}

.inventionAreas {
  /* margin-left: 3%; */
  margin-top: 5%;
  width: 90%;
}

.forImages {
  height: 586px;
}

.industryAreas {
  display: flex;
  margin-top: 5%;
  flex-direction: column;
  align-items: baseline;
  margin-left: 20px;
}

.industryAreaText {
  background-color: white;
  width: auto;
  font-size: 0.8rem;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
  padding: 0px;
}

.pageStrip {
  background: linear-gradient(to right, #1834dd, #d079df);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: 0px;
  padding: 10px;
  margin-top: 30px;
}

.featureList {
  margin-top: 2%;
}

.featureList li {
  margin-left: 6%;
  margin-top: 1%;
  list-style: disc;
  color: black;
  font-size: 1rem;
  line-height: 1.4;
  padding: 10px;
  width: 90%;
}

.featureList li:hover {
  background-color: #020b26;
  color: white;
  border-radius: 10px;
}

.leftSection2 {
  background-image: url("/public/ai-bg.gif");
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
  margin: 0;
  padding: 0;
}

.noveltyTitle {
  font-size: 1.3rem;
  margin-left: 10%;
  margin-top: 30%;
  font-weight: bold;
}

.noveltyText {
  font-size: 2.5rem;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 5%;
}

.belowNovelty {
  width: 55%;
  margin-left: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(-50%);
}

.novelList {
  margin-top: 3%;
}

.novelList li {
  margin-left: 12%;
  list-style: disc;
  color: black;
  font-size: 1rem;
  line-height: 1.4;
  padding: 5px;
  width: 75%;
}

.scorePara {
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  background-color: #020b26;
  color: white;
  margin-left: 10%;
  margin-top: 5%;
  width: 75%;
  line-height: 1.4;
  font-size: 1.2rem;
}

.novelTable {
  margin-top: 20%;
  margin-left: 8%;
  font-size: 1.3rem;
  width: 80%;
}

.novelTableHead {
  background: linear-gradient(to right, #1834dd, #d079df);
}

.scoreLabels {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 8%;
  margin-top: 8%;
  width: 80%;
  font-weight: bold;
}

.scoreLabels p {
  font-size: 0.8rem;
}

.referHead {
  font-weight: bold;
  margin-left: 5%;
  width: auto;
  margin-top: 20px;
}

.referTitle {
  font-weight: bold;
  margin-left: 5%;
  font-size: 1.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
}

.pageStrip2{
  background: linear-gradient(to right, #020b26, #020b26);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: 0px;
  padding: 10px;
  margin-top: 30px;
}

.contentWrapper{
  margin-left: 10%;
  width: auto;
  margin-top: 20px;
}

.reportGrid{
  margin-left: 10%;
  width: 80%;
}

.reportAbstractContainer{
  margin-left: 50px;
  margin-top: 20px;
}

.aboveExcerpt{
  width: 27%;
  margin-top: 2%;
  margin-left: 35%;
  background-color: rgb(199, 198, 198);
  color: rgb(199, 198, 198);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(-50%);
  height: 0.5px;
}

.referContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 10%;
}

.referAnalysis{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 17%;
}

.referAnalysis p{
  display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: auto;
    height: 40px;
    color: #ffffff;
    padding: 8px 16px;
    margin-right: 10px;
    background-color: #020b26;
    border: 1.92px solid;
    border-color: #eff0f6;
    border-radius: 8px;
    transition: color 0.2s ease-in-out;
}

.referPatentPara{
  margin-left: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
  font-weight: bold;
}

.referScrollableContainer{
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 95%;
  margin-left: 3.5%;
}

.referColumnsContainer{
  /* display: flex;
  flex-wrap: wrap; */
  width: 95%;
}

.referPatentList{
  flex: 0 0 50%;
  /* max-width: calc(50% - 20px); 
  margin-right: 20px;  */
}

.referClaimContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 10%;
  margin-top: 5%;
}

.claimAnalysis{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.claimAnalysis p{
  display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: auto;
    height: 40px;
    color: #ffffff;
    padding: 8px 16px;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #020b26;
    border: 1.92px solid;
    border-color: #eff0f6;
    border-radius: 8px;
    transition: color 0.2s ease-in-out;
}

.belowOverall {
  width: 20%;
  top: 30%;
  margin-top: 1%;
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(-50%);
}

.paraHead{
  margin-left: 10%;
    margin-top: 20%;
    font-weight: bold;
}

.reportSummary{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.lastTechnology{
  background: linear-gradient(to right, #1834dd, #d079df);
  color: white;
  width: 50%;
}

.lastRefer{
  margin-left: 10%;
}

.lastHead{
  margin-left: 10%;
  margin-top: 3%;
  margin-bottom: 2%;
}

.lastSummary{
  margin-left: 10%;
  margin-top: 5%;
}

.reportMap{
  margin-left: 10%;
  margin-top: 3%;
}

.lastNoveltyText{
  font-size: 4rem;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 5%;
}

.lastNoveltyTitle{
  font-size: 1.3rem;
  margin-left: 10%;
  margin-top: 5%;
}

.lastReportText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 25%;
}

.clickToButtons{
  display: flex;
  flex-direction: row;
}

.shareButtonReport{
  width: 12%;
    margin-left: 10%;
    margin-top: 33%;
    cursor: pointer;
}

.convertButton{
  width: 20%;
  margin-top: 33%;
  cursor: pointer;
}

.spinner {
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #020b26;
  border-radius: 50%;
  animation: spin 1s linear infinite; 
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



@media (max-width: 768px) {
  .rightSection1{
    height: 500px;
  }

  .inventionText{
    margin-left: 6%;
  }

  .inventionPara{
    margin-left: 7%;
    width: 80%;
  }

  .leftText {
    margin-top: 10%;
    margin-left: 5%;
  }

  .bottomRight{
    width: 65%;
    margin: 5px;
    text-align: right;
    margin-bottom: -15%;
  }

  .name{
    margin-top: 35px;
  }

  .belowUserInfo{
    margin-left: 5%;
    margin-top: 15px;
  }

  .aboutText{
    margin-left: 5%;
  }

  .title{
    margin-left: 5%;
  }

  .belowAbout{
    margin-left: 5%;
  }

  .paraHeading{
    margin-left: 5%;
  }

  .paraInvention{
    margin-left: 5%;
    width: 90%;
  }

  .industryAreas{
    margin-left: 3%;
  }

  .novelWrap{
    margin-left: 4%;
  }

  .noveltyTitle {
    font-size: 1.3rem;
    margin-left: 5%;
    margin-top: 7%;
    font-weight: bold;
  }

  .noveltyText{
    margin-left: 5%;
  }

  .belowNovelty{
    margin-left: 5%;
  }

  .leftSection2 {
    height: 400px;
  }

  .referTitle{
    margin-left: 5%;
    width: 95%;
  }

  .reportAbstractContainer{
    margin-left: -2%;
    margin-top: 10px;
  }

  .aboveExcerpt{
    margin-top: 85%;
  }

  .referScrollableContainer{
    margin-left: 0px;
  }

  .below103References{
    margin-top: 0px;
  }

  .lastbelowNovelty{
    margin-left: 10%;
  }

  .lastNoveltyText{
    margin-left: 6%;
  }

  .featureList li {
    margin-left: 10%;
  }
}