.barSearch{
    font-size: 1rem;
}

.headingSearch{
    font-size: 0.8rem;
}

.forDataTag{
    font-size: 0.8rem;
}

.para{
    font-size: 1rem;
}

.ringText{
    font-size: 1.3rem;
}

.descText{
    font-size: 1rem;
}

.marketTrendsTag{
    font-size: 0.8rem;
}

.forTrendsTag{
    font-size: 0.8rem;
}

.trendText{
    font-size: 1rem;
}

.trendsSpan{
    font-size: 1rem;
}

.forNavTag{
    font-size: 0.8rem;
}

.timeText{
    font-size: 1.3rem;
}

.spanTimeText{
    font-size: 1rem;
}

.mapNavTag{
    font-size: 0.8rem;
}

.mapText{
    font-size: 1.3rem;
}

.spanText{
    font-size: 1rem;
}

.forIndustryTag{
    font-size: 0.8rem;
}

.industryText{
    font-size: 1.3rem;
}

.spanIndustryText{
    font-size: 1rem;
}

.companyText{
    font-size: 1.3rem;
}