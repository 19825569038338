.company-logos-container {
    display: flex;
    overflow: auto;
    /* margin-top: 20px; */
    margin-bottom: 70px;
  }
  
  .company-logo {
    margin-top: 20px; 
    margin-bottom: 20px; 
    margin-right: 10%;
  }
  
  .company-logo img {
    width: 274px; 
    height: 77px;
  }

  .lineAbovePartners{
    width: 14%;
    margin-left: 43%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .logoName{
    font-size: 0.8rem;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .lineAbovePartners{
      width: 50%;
      margin-left: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
  }