h2.patentdetails-title{
    margin-left: 15px;
    margin-top: 10px;
    font-size: 0.8rem;
    font-weight: 300;
    color: #838c95;
}

.actionButtons{
    display: flex;
    flex-direction: row;
}

.likeButton{
    margin-top: 10px;
    cursor: pointer;
    width: 7%;
    text-align: center;
}

.popText{
    float: right;
    cursor: pointer;
}

.popText2{
    margin-top: -14px;
    padding-left: 10px;
    padding-right: 10px;
}

.shareButton{
    margin-top: 15px;
    cursor: pointer;
    width: 5%;
    text-align: center;
}

@media (max-width: 768px) {
    h2.patentdetails-title {
        margin-top: 10px;
      }
    
      .likeButton,
      .shareButton {
        width: 10%;
      }
    
      .likeButton {
        margin-left: 5%;
      }
    
      .shareButton {
        margin-left: 2%;
      }
}

