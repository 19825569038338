/* .scrollable-container {
  overflow-x: auto;
  max-height: 400px;
}

.columns-container {
  display: flex;
  flex-wrap: nowrap;
}

.Dashboard_widgetBlock__Myojl {
  flex: 0 0 200px;
  margin-right: 10px;
}

.carouselCard {
  margin-right: 50px;
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: white;
  cursor: pointer;
}

.body-2 {
  white-space: normal;
  word-wrap: break-word;
}

.body-3.muted {
  white-space: normal;
  word-wrap: break-word;
}

.subText {
  font-size: 0.8rem;
}

.inventorName {
  font-size: 1rem;
}

.inventorTags {
  font-size: 0.8rem;
}

.carouselData {
  width: 200px;
  height: 80px;
  padding-top: 10px;
}

.carouselCard:hover {
  background-color: #f4f2ec;
} */

/* Container for the carousel */
.carousel-container {
  width: 100%;
  padding: 10px;
  /* border-radius: 12px; */
}

/* .gradient-background{
  background: linear-gradient(to right, #fcf6f5, #f9d7e0); 
} */

.heading {
  padding: 15px;
  text-align: center;
  font-size: 1rem; 
  font-weight: bold;
  /* margin-bottom: 20px; */
}

.scrollable-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
}

.columns-container {
  display: flex;
  flex-wrap: nowrap;
}

.carouselCard {
  flex: 0 0 320px; 
  margin: 0 15px; 
  background: white; 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.companyName {
  font-size: 1.3rem;
  font-weight: bold;
  /* color: white; */
  margin-bottom: 10px;
}

.companyCountry {
  font-size: 1rem; 
  /* color: white; */
  margin-bottom: 10px;
}

.industryFocus,
.technologiesDeveloped,
.topInventor {
  margin-top: 20px;
}

.industryFocus h4,
.technologiesDeveloped h4,
.topInventor h4 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  /* color: white; */
  border-bottom: 0.25px solid rgb(24, 52, 221); 
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.industryFocus p,
.technologiesDeveloped p,
.topInventor p {
  margin: 5px 0;
  font-size: 1rem; 
  /* color: white; */
}

.carouselData  {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .carouselCard {
    flex: 0 0 280px; 
    padding: 15px;
    margin: 0 10px;
  }

  .heading {
    font-size: 1.4rem; 
    padding: 10px;
  }

  .companyName {
    font-size: 1rem;
    /* color: white; */
  }

  .companyCountry {
    font-size: 0.7rem; 
    /* color: white; */
  }

  .industryFocus h4,
  .technologiesDeveloped h4,
  .topInventor h4 {
    font-size: 0.7rem; 
    /* color: white; */
  }

  .industryFocus p,
  .technologiesDeveloped p,
  .topInventor p {
    font-size: 0.7rem; 
    /* color: white; */
  }

  .heading{
    font-size: 0.7rem;
  }
}


